const prices = ['FREE', 'BASIC', 'PRO', 'ENTERPRISE']

export function get_upgrade(current_price){
  return prices[prices.indexOf(current_price)+1]
}

export function get_initials(firstname, lastname){
  // Get the first character of each string and convert them to uppercase
  const initial1 = firstname.charAt(0).toUpperCase();
  const initial2 = lastname.charAt(0).toUpperCase();

  // Return the initials as a single string
  return initial1 + initial2;
}
