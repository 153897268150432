import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCarSide, faPhone, faMusic } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-regular-svg-icons'; // Corrected import for regular style
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Configure axios
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Add icons to the library
library.add(faPhone, faCarSide, faComments, faMusic); // Now includes the correct 'comments' icon

// Create Vue application
const app = createApp(App);

// Register FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use router
app.use(router);

// Mount the Vue application
app.mount('#app');
