<template>
  <div id="container-help">
    <h1 class="title">
      <a href="https://risso.ai">
        <img
          src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_logo.png"
          href="https://risso.ai"
        />
      </a>
    </h1>
    <div id="container-text">
      <section id="introduction">
        <h1>Welcome to the Help Section of Risso AI!</h1>
        <p>
          This guide is designed to help you navigate through our platform
          effortlessly, making the most out of the tools and features at your
          disposal. Whether you're here to manage AI models, explore API
          documentation, adjust your plans, or experiment with our Playground,
          we've got you covered.
        </p>
      </section>

      <section id="dashboard">
        <h2>Dashboard - <span>Your Command Center</span></h2>
        <p>
          The Dashboard serves as your central panel for monitoring and managing
          your engagement with Risso AI. Here, you'll find an overview of your
          current plan's usage, tools for managing your AI models, and the
          ability to create, delete, or modify tokens. This intuitive interface
          ensures that you're always in control of your resources and can make
          adjustments as your project evolves.
        </p>
      </section>

      <section id="api-docs">
        <h2>API Docs - <span>Your Technical Companion</span></h2>
        <p>
          Under the "API Docs" section, delve into the comprehensive technical
          documentation for our API. Whether you're a seasoned developer or just
          starting out, our documentation provides all the information you need
          to seamlessly integrate and utilize our services within your projects.
        </p>
      </section>

      <section id="plans">
        <h2>Plans - <span>Tailor Your Experience</span></h2>
        <p>
          Our "Plans" section is designed to let you easily switch between
          different subscription plans, ensuring that you always have the right
          level of access for your needs. Selecting a new plan redirects you to
          a secure checkout page on Stripe, making the transition smooth and
          secure.
        </p>
      </section>

      <section id="playground">
        <h2>Playground - <span>Experiment and Explore</span></h2>
        <p>
          The "Playground" is your sandbox for creativity and experimentation.
          Here, you can upload a file, engage with the models and endpoints
          available to you, and receive immediate feedback in the form of a
          JSON-formatted string, identical to our API's output. Please note that
          using the Playground consumes API calls and access is limited to the
          models and endpoints included in your subscription.
        </p>
      </section>

      <section id="report">
        <h2>Report - <span>For Extensive Analysis</span></h2>
        <p>
          "Report" is tailored for more comprehensive tasks. Unlike Playground,
          you can upload multiple files simultaneously for sound recognition,
          enhancing efficiency for larger workloads. Results can be conveniently
          downloaded as a CSV file, offering a versatile format for further
          analysis or reporting.
        </p>
      </section>

      <section id="upload-files">
        <h2>Upload Files - <span>Custom AI Models</span></h2>
        <p>
          In the "Upload Files" section, you can submit a large volume of files,
          typically as part of a request for a custom AI model. Before
          proceeding, please ensure you've contacted Risso to discuss your
          custom model requirements.
        </p>
      </section>

      <footer>
        <p>
          Need further assistance? Don't hesitate to reach out to our support
          team at <a href="mailto:help@risso.ai">help@risso.ai</a>
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpComponent'
}
</script>

<style scoped>
.title {
  position: relative;
  width: 100%;
  margin-top: 5%;
}
img {
  border-top: 2%;
  position: relative;
  width: 20%;
}
#container-help {
  position: relative;
  width: 83%;
  margin: 0;
  min-height: 100%;
  height: fit-content;
  text-align: center;
  --radius: 20px;
  --break-small: 320px;
  --break-large: 1200px;
  --stable-padding: 20px;
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
  border-left: 2px solid var(--grey);
  background: linear-gradient(to right, var(--white), var(--light-green));
}
#container-text {
  text-align: left;
  margin: 5%;
}

h1,
h2 {
  color: var(--dark-green);
}

section {
  background: transparent;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  color: var(--medium-green);
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

/* h2 span:first-child {
    color: #007BFF;
    font-weight: bold;
} */

h2 span:last-child {
  color: var(--dark-grey);
  font-weight: normal;
}

p {
  margin: 10px 0;
  line-height: 1.5;
}

footer {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  color: var(--dark-grey);
}

@media screen and (max-width: 1200px) {
  .container-help {
    position: absolute;
    left: 17%;
  }
}
</style>
