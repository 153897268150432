<template>
  <div class="login-container" id="login-container">
    <!-- Pop-up modal for terms and conditions -->
    <div class="modal" v-if="termsVisible">
      <div class="modal-content">
        <span id="close-icon" class="close" @click="hideTerms">&times;</span>
        <!-- Terms and conditions content -->
        <div class="terms-content" @scroll="checkScroll">
          <div id="logo-terms" class="logo-terms">
            <img
              src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_logo.png"
              href="https://risso.ai"
            />
          </div>
          <!-- Long terms and conditions text -->
          <p>
            We appreciate your use of Risso's APIs, developer services, and
            software (collectively known as "APIs"). By accessing or utilizing
            our APIs, you signify your agreement to the terms outlined below. In
            the event of a conflict between these terms and any additional terms
            that apply to a particular API, the additional terms will take
            precedence. The "Terms" consist of the terms listed below, as well
            as any accompanying API documentation, additional terms, applicable
            policies, and guidelines. Your use of our APIs acknowledges that you
            are bound by these Terms and that they govern your relationship with
            us. It is essential that you carefully read and comprehend all of
            the Terms. If you use our APIs in conjunction with other Risso
            products or services, the terms and conditions that are relevant to
            those products or services will also be applicable.
          </p>

          <p>
            In these Terms, "Risso" denotes Risso Ltd., with its primary office
            located at 77 John Rogerson's Quai, Dublin, Ireland, unless
            additional terms related to a specific API indicate otherwise. In
            these Terms, "we," "our," or "us" refer to Risso.
          </p>

          <h2>Licence Grant</h2>
          <p>
            Subject to your compliance with these Terms, we grant you a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable
            license to access and use the API solely for your internal business
            purposes.
          </p>

          <h2>API Use Restrictions</h2>
          <p>You shall not:</p>
          <ol>
            <li>
              Use the API in any manner that violates any applicable laws or
              regulations;
            </li>
            <li>
              Use the API to develop, distribute or support any products or
              services that compete with our products or services;
            </li>
            <li>
              Exceed any usage limits or restrictions specified in our
              documentation or otherwise communicated to you;
            </li>
            <li>
              Reverse engineer, decompile, disassemble or otherwise attempt to
              derive the source code of the API or any underlying technology;
            </li>
            <li>
              Use the API for any purpose or in any manner that infringes or
              misappropriates any intellectual property rights of any third
              party;
            </li>
            <li>
              Access or use the API in order to build a similar or competitive
              product or service.
            </li>
          </ol>

          <h2>API Limitations</h2>
          <p>
            Risso imposes restrictions on your use of the APIs, such as the
            number of API requests or users served, as documented for each API.
            You must comply with these limitations and not try to bypass them.
            To exceed these limits, you must obtain Risso's explicit approval,
            which may require agreeing to additional terms and/or fees. Contact
            the relevant Risso API team through the Risso developers console for
            more information.
          </p>

          <h2>API Modifications</h2>
          <p>
            We reserve the right to modify, suspend, or discontinue the API, or
            any part thereof, at any time and without prior notice to you. We
            will not be liable to you or any third party for any such
            modifications, suspensions, or discontinuations.
          </p>

          <h2>Proprietary Rights</h2>
          <p>
            Intellectual Property Rights. We retain all right, title, and
            interest in and to the API, including all intellectual property
            rights therein. These Terms do not grant you any rights in our
            trademarks, logos, or any other intellectual property.
          </p>

          <p>
            Your Content. By using the API, you consent to our use of your
            content ("Your Content") to provide the Services to you. You also
            agree and instruct that we may use, store, process, and transmit
            Your Content to maintain and provide the applicable Services and to
            develop, improve, and enhance our services and technology ("Our
            Technology"). You represent and warrant that you have obtained all
            necessary rights, licences, and permissions to grant us the right to
            use Your Content as described in these Terms.
          </p>

          <p>
            Feedback. In case you offer us any Feedback about the functionality
            or performance of the Services, including identifying potential
            errors and improvements, you acknowledge that you are assigning all
            rights and interests in and to such Feedback to Risso. Risso is free
            to use the Feedback without any restrictions or obligation to pay
            you.
          </p>

          <p>
            Trademarks. You grant us permission to include your name, logos, and
            trademarks in our promotional and marketing materials and
            communications.
          </p>

          <p>
            Open source software. Risso may use open source materials in the
            Services, and you should be aware that specific terms required by
            those open source materials may apply to their use. Risso will make
            reasonable efforts to include these terms in the relevant
            documentation, but they will not impose any additional restrictions
            on your use of the Services or affect Risso's responsibilities with
            respect to the Services.
          </p>

          <h2>Fees and Payment</h2>
          <p>
            If you subscribe to a paid plan for the API, you agree to pay the
            applicable fees set forth in our pricing documentation. All fees are
            non-refundable and must be paid in advance. We reserve the right to
            change our pricing and to institute new charges at any time, upon
            notice to you.
          </p>

          <h2>Data Privacy and Security</h2>
          <p>
            By using the API, you agree to our collection, use, and storage of
            data submitted by you, in accordance with our Privacy Policy. We
            will maintain appropriate administrative, physical, and technical
            safeguards to protect the security, confidentiality, and integrity
            of your data.
          </p>

          <h2>Termination</h2>
          <p>
            We may terminate your access to and use of the API, at our sole
            discretion, at any time and without notice to you. You may terminate
            your use of the API at any time by discontinuing your access to and
            use of the API. Upon any termination, your license to use the API
            will immediately cease.
          </p>

          <h2>Disclaimer of Warranties</h2>
          <p>
            The API is provided on an "as is" and "as available" basis, without
            any warranties of any kind, either express or implied, including,
            but not limited to, warranties of merchantability, fitness for a
            particular purpose, or non-infringement. We make no warranty that
            the API will meet your requirements, be uninterrupted, secure, or
            error-free.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            Limitation of Liability for Consequential Damages. In no event will
            we be liable for any direct, indirect, incidental, special,
            consequential, or punitive damages arising out of or in connection
            with your use of the API, even if we have been advised of the
            possibility of such damages.
          </p>

          <p>
            Liability Cap. Risso's aggregate liability to you for all claims
            arising from these Terms or Services is limited to the amount paid
            or payable by you under these Terms in the twelve (12) months
            preceding the claim.
          </p>

          <p>
            In all cases Risso, along with its suppliers, will not be held
            responsible for any expenses, losses, or damages that could not have
            been reasonably foreseeable.
          </p>

          <h2>Governing Law</h2>
          <p>
            These Terms will be governed by and construed in accordance with the
            laws of Ireland, without regard to its conflict of law provisions.
          </p>

          <h2>Changes to These Terms</h2>
          <p>
            Risso may modify these Terms at any time at its sole discretion. If
            any updates are made, Risso may provide notice of such changes,
            which may include sending an email notification to the email address
            in your Account or updating the "Last Updated" date at the beginning
            of these Terms. You agree to be bound by any updated Terms and all
            terms incorporated therein by continuing to access or use the
            Services after the date specified in the notice. If you do not agree
            with the updated Terms, your only option is to deactivate your
            Account and discontinue use of the Website and/or Services.
          </p>

          <h2>Definitions</h2>
          <ul>
            <li>"API": Application programming interface provided by Risso.</li>
            <li>
              "Terms": The terms of service governing the use of the API and
              related services
            </li>
            <li>"Services": The services provided through the API.</li>
            <li>
              "Your Content": The data and content submitted by you while using
              the API and other services provided by the Risso.
            </li>
            <li>
              "Our Technology": The technology, algorithms, and intellectual
              property used by Risso to provide the API and Services.
            </li>
            <li>
              "License Grant": A limited, non-exclusive, non-transferable,
              non-sublicensable, revocable licence to access and use the API.
            </li>
            <li>
              "Intellectual Property Rights": Rights to inventions, patents,
              copyrights, trademarks, trade secrets, and other proprietary
              rights associated with the API and other software.
            </li>
            <li>
              "Privacy Policy": The policy governing the collection, use, and
              storage of data submitted by you while using the API.
            </li>
            <li>
              "Jurisdiction": The legal jurisdiction governing the
              interpretation and enforcement of these Terms.
            </li>
            <li>
              "Fees": The applicable charges for using the API as specified in
              the pricing documentation.
            </li>
          </ul>
          <button id="close-button" @click="hideTerms">Close</button>
        </div>
      </div>
    </div>
    <div class="form-container sign-up">
      <form @submit.prevent="submitSignupForm" id="signUpForm">
        <div id="logo" class="logo-signup">
          <img
            src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_logo.png"
            href="https://risso.ai"
          />
        </div>
        <h1>Create Account</h1>
        <input
          type="text"
          v-model="firstName"
          placeholder="First Name"
          required
        />
        <input
          type="text"
          v-model="lastName"
          placeholder="Last Name"
          required
        />
        <input type="text" v-model="company" placeholder="Company" required />
        <input type="email" v-model="email" placeholder="Email" required />
        <input
          type="password"
          v-model="password"
          placeholder="Password"
          required
        />
        <!-- Text that user clicks to open the terms and conditions pop-up -->
        <div class="form">
          <input
            type="checkbox"
            id="agree"
            v-model="agreed"
            @select="isDisabled"
          />
          <a>I accept <b @click="showTerms">Terms and Conditions</b></a>
        </div>
        <button id="signup-button" type="submit" :disabled="!agreed">
          Sign Up
        </button>
        <h5 class="error-login" id="error-signup">
          Failed to sign up! <br />Please check your data, if the problem
          persists refresh the page and try again.
        </h5>
      </form>
    </div>

    <div class="form-container sign-in">
      <form @submit.prevent="submitSigninForm" id="signInForm">
        <div id="logo" class="logo-signin">
          <img
            src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_logo.png"
            href="https://risso.ai"
          />
        </div>
        <h1>Sign In</h1>
        <input v-model="email" type="email" placeholder="Email" required />
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          required
        />
        <!--  <a href="#">Forgot your password?</a> -->
        <button type="submit">Sign In</button>
        <h5 class="error-login" id="error-signin">
          Failed to sign in! <br />Please check your data, if the problem
          persists refresh the page and try again.
        </h5>
      </form>
    </div>

    <div class="toggle-container">
      <div class="toggle">
        <div class="toggle-panel toggle-left">
          <h1>Already signed up?</h1>
          <p>Explore sound with AI</p>
          <button class="hidden-login" id="login" @click="handleLogin">
            Sign In
          </button>
        </div>

        <div class="toggle-panel toggle-right">
          <h1>Not registered yet?</h1>
          <p>Create an account and start your AI journey</p>
          <button class="hidden-login" id="register" @click="handleSignup">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  props: ['param'],
  data() {
    return {
      logged: true,
      page_show: 'Login',
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      password: '',
      termsVisible: false,
      termsScrolled: false,
      agreed: false
    }
  },
  mounted() {
    if (this.param) {
      this.handleSignup()
    }
  },
  methods: {
    isDisabled() {
      return this.agreed
    },
    showTerms() {
      this.termsVisible = true
    },
    hideTerms() {
      this.termsVisible = false
      if (this.agreed == true) {
        const submit_signup = document.getElementById('signup-button')
        submit_signup.disabled = false
      } else {
        const submit_signup = document.getElementById('signup-button')
        submit_signup.disabled = true
      }
    },
    handleSignup() {
      const container = document.getElementById('login-container')
      container.classList.add('active')
      const errorMessage = document.getElementById('error-signin')
      errorMessage.style.visibility = 'hidden'
    },
    handleLogin() {
      const container = document.getElementById('login-container')
      container.classList.remove('active')
      const errorMessage = document.getElementById('error-signup')
      errorMessage.style.visibility = 'hidden'
    },
    async submitSignupForm() {
      const errorMessage = document.getElementById('error-signup')
      try {
        if (!this.agreed) {
          errorMessage.textContent =
            'You need to read and accept Terms & Conditions before proceeding!'
          errorMessage.style.visibility = 'visible'
        } else {
          const response = await fetch('https://auth.risso.ai/user/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              first_name: this.firstName,
              last_name: this.lastName,
              company: this.company,
              email: this.email,
              password: this.password
            })
          })

          if (response.status == 500) {
            errorMessage.textContent =
              'Failed to sign up! \nPlease check your data, if the problem persists refresh the page and try again.'
            errorMessage.style.visibility = 'visible'
          } else if (response.status == 400) {
            const errorMessage = document.getElementById('error-signup')
            errorMessage.textContent =
              'Email already associated with an existing account. \n Please try signing in instead, or use a different email to create a new account.'
            errorMessage.style.visibility = 'visible'
          } else {
            this.handleLogin()
          }
        }
      } catch (error) {
        const errorMessage = document.getElementById('error-signin')
        errorMessage.style.visibility = 'visible'
      }
    },
    async submitSigninForm() {
      localStorage.clear()
      try {
        const response = await fetch('https://auth.risso.ai/jwt/get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.email,
            password: this.password
          })
        })
        const data = await response.json()

        if (response.status == 500) {
          const errorMessage = document.getElementById('error-signin')
          errorMessage.textContent =
            'Failed to sign in!\nMight be some problems with server, please try again!'
          errorMessage.style.visibility = 'visible'
        } else if (response.status == 404) {
          const errorMessage = document.getElementById('error-signin')
          errorMessage.textContent =
            'Failed to sign in!\nPlease double-check your password and your email and try again.'
          errorMessage.style.visibility = 'visible'
        } else {
          sessionStorage.setItem('myToken', data['JWT'])
          sessionStorage.setItem('id', data['id'])
          sessionStorage.setItem('authenticated', true)
          sessionStorage.setItem('email', this.email)
          this.$router.push('/')
        }
      } catch (error) {
        const errorMessage = document.getElementById('error-signin')
        errorMessage.style.visibility = 'visible'
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
body {
  background-color: var(--light-green);
  background: linear-gradient(to right, var(--white), var(--light-green));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
:root {
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--highlight-green);
}

i {
  font-size: 24px;
}

b {
  cursor: pointer;
}
#terms-label {
  color: crimson;
  cursor: pointer;
}

ol,
li {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}
label {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
  color: #73b170;
}
#logo {
  position: relative;
  width: 60%;
  margin-bottom: 5%;
}
img {
  position: relative;
  width: 100%;
}

#signup-button:disabled {
  background: var(--grey);
  color: #555;
  cursor: not-allowed;
}

/* Styles for the modal */
.modal {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 45%;
  overflow-y: scroll;
}

.form {
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
}
#close-icon {
  font-size: xx-large;
  color: darkred;
}

#close-button {
  position: relative;
  background-color: darkred;
  color: white;
  left: 85%;
  width: fit-content;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Style for disabled label */

#agree {
  width: 5%;
  margin-left: 5px;
  margin-right: 5px;
}
.error-login {
  color: crimson;
  width: fit-content;
  font-size: 12px;
  margin-top: 2%;
  visibility: hidden;
  text-align: center;
  line-height: 1.5em;
}
body {
  top: 0%;
  position: absolute;
  width: 100%;
  height: 100%;
}
#app {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.login-container {
  position: absolute;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  width: 60%;
  max-width: 100%;
  height: 70%;
  left: 20%;
  top: 15%;
}

.login-container p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}

.login-container span {
  font-size: 12px;
}

.login-container a {
  color: var(--medium-green);
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
}

.login-container h1 + input {
  margin-top: 40px;
}

.login-container input + button {
  margin-top: 5%;
}

.login-container button {
  background-color: var(--dark-green);
  color: var(--white);
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.login-container button.hidden-login {
  background-color: transparent;
  border-color: var(--white);
}

.login-container form {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
  margin-top: 8%;
}

.login-container input {
  background-color: var(--grey);
  border: none;
  margin: 8px 0;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in {
  left: 0;
  width: 50%;
  z-index: 2;
}

.login-container.active .sign-in {
  transform: translateX(100%);
}

.sign-up {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.login-container.active .sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.social-icons {
  margin: 20px 0;
}

.social-icons a {
  border: 1px solid var(--grey);
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
}

.toggle-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 1000;
}

.login-container.active .toggle-container {
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}
#logo-terms {
  width: 20%;
}
.toggle {
  background-color: var(--dark-green);
  height: 100%;
  background: linear-gradient(
    to right,
    var(--dark-green),
    var(--highlight-green)
  );
  color: var(--white);
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.login-container.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle-left {
  transform: translateX(-200%);
}

.login-container.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.login-container.active .toggle-right {
  transform: translateX(200%);
}

@media screen and (max-width: 1000px) {
  .login-container {
    position: absolute;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    width: 85%;
    max-width: 100%;
    height: 70%;
    left: 7.5%;
    top: 15%;
  }
  .form-container {
    display: flex;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  #signInForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    align-items: center;
    align-content: center;
  }
  #signUpForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    margin-top: 45%;
    align-items: center;
    align-content: center;
  }
}

/* @media only screen and (max-height: 750px) {

  #app {
    position: relative;
    width: 60%;
    height: 70%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    position: absolute;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    width: 85%;
    max-width: 100%;
    height: 70%;
    left: 7.5%;
    top: 15%;
  }

  .form-container {
    display: flex;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .login-container a {
    color: var(--medium-green);
    font-size: 9px;
    text-decoration: none;
    margin: 15px 0 10px;
  }
  .login-container p {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.3px;
    margin: 14px 0;
  }
  .login-container input {
    font-size: 10px;
  }
  .login-container span {
    font-size: 8px;
  }
  .login-container h1 + input {
    margin-top: 10px;
    font-size: 10px;
  }

  .login-container input + button {
    margin-top: 5px;
    font-size: x-small;
  }

  #signInForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    margin-top: 100%;
    align-items: center;
    align-content: center;
  }

  #signInForm button {
    font-size: x-small;
  }

  #signInForm input {
    padding: 10% 10%;
  }
  .toggle-right h1 {
    font-size: 24px;
  }

  .toggle-left h1 {
    font-size: 24px;
  }
  #signUpForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    margin-top: 45%;
    align-items: center;
    align-content: center;
  }
} */

@media only screen and (max-height: 750px) {
  /* CSS rules for mobile devices */
  #app {
    position: relative;
    width: 60%;
    height: 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    position: absolute;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    width: 85%;
    max-width: 100%;
    height: 70%;
    left: 7.5%;
    top: 15%;
  }

  .form-container {
    display: flex;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: white;
    overflow: scroll;
  }

  .login-container a {
    color: var(--medium-green);
    font-size: 9px;
    text-decoration: none;
    margin: 15px 0 10px;
  }
  .login-container p {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.3px;
    margin: 14px 0;
  }
  .login-container input {
    font-size: 10px;
  }
  .login-container span {
    font-size: 8px;
  }
  .login-container h1 + input {
    margin-top: 10px;
    font-size: 10px;
  }

  .login-container input + button {
    margin-top: 5px;
    font-size: x-small;
  }

  #signInForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    align-items: center;
    align-content: center;
    margin-top: 10%;
  }

  #signInForm button {
    font-size: x-small;
  }

  #signInForm input {
    padding: 4% 4%;
  }
  .toggle-right h1 {
    font-size: 24px;
  }

  .toggle-left h1 {
    font-size: 24px;
  }
  #signUpForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    align-items: center;
    align-content: center;
    margin-top: 15%;
  }
  #logo {
    margin-bottom: 5%;
    width: 45%;
  }
}

@media only screen and (max-width: 440px) {
  /* CSS rules for mobile devices */
  #app {
    position: relative;
    width: 60%;
    height: 70%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    position: absolute;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    width: 85%;
    max-width: 100%;
    height: 70%;
    left: 7.5%;
    top: 15%;
  }

  .form-container {
    display: flex;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: scroll;
  }
  .login-container a {
    color: var(--medium-green);
    font-size: 9px;
    text-decoration: none;
    margin: 15px 0 10px;
  }
  .login-container p {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.3px;
    margin: 14px 0;
  }

  .login-container span {
    font-size: 9px;
  }
  .login-container h1 + input {
    margin-top: 40px;
  }

  .login-container input + button {
    margin-top: 10px;
  }

  #signInForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    margin-top: 100%;
    align-items: center;
    align-content: center;
  }

  #signInForm button {
    padding: 15% 20%;
  }

  #signInForm input {
    padding: 15% 20%;
  }
  .toggle-right h1 {
    font-size: 24px;
  }

  .toggle-left h1 {
    font-size: 24px;
  }
  #signUpForm {
    padding: 0%;
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    margin-top: 100%;
    align-items: center;
    align-content: center;
  }
}
</style>
