<template>
  <div class="container-drag">
    <h1 class="title">Playground</h1>
    <!-- <div id="explanation">
            <p>Upload one file at a time, choose your options, and detect sounds.</p>
        </div> -->
    <h5 class="error" id="error-drag"></h5>
    <div id="top-container">
      <div id="drop-div">
        <div
          class="drop-area"
          @dragover.prevent
          @drop="handleDrop"
          @click="openFileDialog"
        >
          <p>Select file to process</p>
          <input
            type="file"
            id="file-input"
            style="display: none"
            @change="handleFileInputChange"
            accept="audio/*"
          />
        </div>
      </div>
      <!-- Player with waveform -->
      <container id="player">
        <div id="name-container">
          <p id="file-status"></p>
        </div>
        <div id="waveform"></div>
      </container>
    </div>
    <div id="middle-container">
      <div class="form">
        <h5>Use case:</h5>
        <select v-model="selectedModel">
          <option
            v-for="(option, index) in models"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="form" id="endpointForm">
        <h5>Endpoint:</h5>
        <select v-model="selectedEndpoint">
          <option value="classify">classify</option>
          <!-- <option value="window">window</option> -->
        </select>
      </div>
    </div>
    <div id="bottom-container">
      <!-- Recognise button to trigger API-->
      <div
        class="recognise-container"
        id="submit-button"
        @click="handleFileUpload"
      >
        <button type="submit">Recognise Sound</button>
      </div>
      <!-- Spinner -->
      <div id="spinner" class="spinner" style="display: none"></div>

      <!-- Result container -->
      <div id="result-container">
        <div class="copy-div">
          <button class="copy-btn" @click="copyText">
            <i class="bx bx-copy"></i>
          </button>
          <dialog id="custom-dialog">
            <p id="dialog-text">Result copied!</p>
          </dialog>
        </div>
        <p id="api-result"></p>
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import 'boxicons/css/boxicons.min.css'
import axios from 'axios'
export default {
  name: 'DragComponent',
  data() {
    return {
      models: [],
      current_plan_id: Number,
      current_token: '',
      selectedEndpoint: 'classify',
      selectedModel: 'generic',
      token: '',
      files: []
    }
  },
  created() {
    this.fetchdata()
  },
  methods: {
    handleButtonClick() {
      console.log(this.selectedOption)
    },
    fetchdata() {
      const requestData = {
        user_id: sessionStorage.getItem('id')
      }
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      // Make a GET request to retrieve plans details
      axiosInstance
        .get('/user/plans', {
          params: requestData
        })
        .then((response) => {
          this.plans = response.data
          const current_plan = this.plans.reduce((prev, current) => {
            return current.is_active ? current : prev
          }, null)

          this.current_plan_id = current_plan.id
          axiosInstance
            .get('/plan/models', {
              params: { plan_id: this.current_plan_id }
            })
            .then((response) => {
              for (const model of response.data) {
                this.pushUniqueModel(model.name)
              }
            })

          // Make a GET request to retrieve tokens details
          axiosInstance
            .get('/plan/tokens', {
              params: { plan_id: this.current_plan_id }
            })
            .then((response) => {
              console.log(response)
              for (const token of response.data) {
                this.current_token = token.token
              }
            })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    },
    pushUniqueModel(model) {
      // Check if the model with the same name already exists in this.models
      const existingModel = this.models.find(
        (existingModel) => existingModel === model
      )

      // If the model with the same name doesn't already exist, push the new model
      if (!existingModel) {
        this.models.push(model)
      }
    },
    handleDrop(event) {
      event.preventDefault()
      const waveform = document.getElementById('waveform')
      while (waveform.firstChild) {
        waveform.removeChild(waveform.firstChild)
      }
      const errorMessage = document.getElementById('error-drag')
      const files = event.dataTransfer.files
      const audioFiles = this.filterAudioFiles(files)
      if (audioFiles.length > 0) {
        this.loadContent(audioFiles[0])
      } else {
        // Show message to warn only audio files are accepted
        errorMessage.textContent = 'Only audio files are admitted!'
        errorMessage.style.display = 'flex'
      }
    },
    handleFileInputChange(event) {
      // Get element to show error messages and sat it to not displayed
      const errorMessage = document.getElementById('error-drag')
      errorMessage.style.display = 'none'

      // Remove Waveform content to update it with another file
      const waveform = document.getElementById('waveform')
      while (waveform.firstChild) {
        waveform.removeChild(waveform.firstChild)
      }

      // Get uploaded files
      const files = event.target.files
      const audioFiles = this.filterAudioFiles(files)
      if (audioFiles.length == 0) {
        errorMessage.textContent = 'Only audio files are admitted!'
        errorMessage.style.display = 'flex'
      }

      // Load content of file
      this.loadContent(audioFiles[0])
    },
    openFileDialog() {
      document.getElementById('file-input').click()
    },
    loadContent(file) {
      if (!file) {
        return
      }
      const fileStatus = document.getElementById('file-status')
      const errorMessage = document.getElementById('error-drag')
      const wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#8ce5aa',
        progressColor: '#2d4e4e',
        barWidth: 0,
        normalize: true,
        mediaControls: true
      })

      if (file) {
        this.files.push(file)
        fileStatus.textContent = file.name
        const url = URL.createObjectURL(file)
        wavesurfer.load(url)
        document.getElementById('result-container').style.display = 'none'

        // Show player when fils are uploaded
        const player = document.getElementById('player')
        const container = document.getElementById('top-container')
        player.style.display = 'inline'
        container.style.display = 'inline-flex'
      } else {
        errorMessage.textContent = 'No file uploaded'
        errorMessage.style.display = 'content'
        wavesurfer.empty()
      }
    },
    async handleFileUpload(event) {
      // Prevent the default form submission
      // Function to handle the file upload and make an API call

      event.preventDefault()
      const errorMessage = document.getElementById('error-drag')
      const audio_files = this.filterAudioFiles(this.files)
      if (audio_files.length == 0) {
        errorMessage.textContent = 'No file uploaded'
        errorMessage.style.display = 'flex'
      } else {
        // Define the API endpoint to be called

        const apiEndpoint =
          'https://rissoai--sound-api-main.modal.run/classify'
          /* ?token=' +
          this.current_token +
          '&model=' +
          this.selectedModel */
        var headers = {
          'token':this.current_token,
          'model':this.selectedModel
        }
        // Init form data
        const formData = new FormData()

        // Get the files into the form
        for (const file of audio_files) {
          formData.append('audio_files', file, file.name) // Append each file to formData
        }

        console.log(this.selectedModel)
        console.log(this.selectedEndpoint)

        // Append data to the FormData object
        formData.append('model_name', this.selectedModel)

        // Show the spinner
        document.getElementById('spinner').style.display = 'block'

        // Try calling the API and catch any errors
        try {
          // Call the API
          const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers:headers,
            body: formData
          })

          const result = await response.json()

          if (response.status == 401) {
            // Show message for token exhausted
            errorMessage.textContent = 'Available tokens exhausted!'
            errorMessage.style.display = 'flex'
          } else if (response.status != 200) {
            // Show message for token exhausted
            errorMessage.textContent =
              'Might be some problems with server, please try again!'
            errorMessage.style.display = 'flex'
          } else {
            // Display the result
            document.getElementById('api-result').textContent = JSON.stringify(
              result,
              null,
              2
            )
            document.getElementById('result-container').style.display = 'flex'
          }
        } catch (error) {
          errorMessage.textContent =
            'Might be some problems with server, please try again!'
          errorMessage.style.display = 'flex'
        } finally {
          // Hide the spinner whether the call was successful or failed
          document.getElementById('spinner').style.display = 'none'
        }
      }
    },
    copyText() {
      // Get the text from the paragraph
      const text = document.getElementById('api-result').textContent
      const dialog = document.getElementById('custom-dialog')
      // Use the Clipboard API to copy the text

      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or change the icon to indicate success
          dialog.showModal()
          document.addEventListener('click', (event) => {
            if (event.target === dialog) {
              dialog.close()
            }
          })
        })
        .catch((err) => {
          alert('Failed to copy text: ', err)
        })
    },
    filterAudioFiles(files) {
      return Array.from(files).filter((file) => {
        return file.type.startsWith('audio/')
      })
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

#top-container {
  display: inline-flex;
  height: 10%;
  width: 92%;
  margin: 4%;
  align-items: center;
  justify-content: center;
  font-family: 'Manrope', sans-serif;
  gap: 10%;
}
#middle-container {
  width: 40%;
  height: 3%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  font-family: 'Manrope', sans-serif;
  gap: 10%;
  margin-bottom: 5%;
  justify-content: center;
}
#bottom-container {
  margin-top: 10%;
  display: contents;
  height: 40%;
  width: 100%;
  align-items: center;
}
.form {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.form h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}
.title {
  margin-top: 3.5%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--highlight-green);
}

i {
  font-size: 24px;
}
.error {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  color: crimson;
  width: 100%;
  margin: 0%;
  text-align: center;
  line-height: 1.5em;
  display: none;
  margin-top: 5%;
}

#drop-div {
  display: flow;
  width: 25%;
  min-width: 200px;
  vertical-align: middle;
  text-align: center;
  margin: 8%;
}
.drop-area {
  display: flex;
  /* position: relative; */
  border-radius: 50px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed var(--dark-grey);
  /* width: 40%; */
  cursor: pointer;
  margin: auto;
  /* padding: 25%; */
}
.drop-area:hover {
  border: 2px dashed var(--dark-green);
  transition: 0.6s;
}

.form h5[data-v-2f693578] {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}
select {
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
}
.drop-area p {
  margin: 20px;
}

dialog {
  position: relative;
  left: 55%;
  top: 79%;
  background: #f5fcf5;
  border: 0px;
  border-radius: 12px;
  font-size: 14px;
  width: 110px;
  height: 28px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
}
#dialog-text {
  position: relative;
  margin: 3%;
}

.container-drag {
  position: relative;
  background-color: transparent;
  top: 0%;
  width: 83%;
  min-height: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
  border-left: 2px solid var(--grey);
  background: linear-gradient(to right, var(--white), var(--light-green));
}

/* Form Styling */
#upload-form {
  /* padding: 20px; */
  border-radius: 8px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.recognise-container {
  align-items: center;
  justify-content: center;
}

/* Button Styling */
.recognise-container button {
  background-color: var(--dark-green);
  color: var(--white);
  padding: 15px 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  margin-bottom: 10%;
  margin-top: 10%;
}

.recognise-container button:hover {
  background-color: var(--light-green);
  color: var(--dark-green);
}

#name-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

#player {
  margin-top: 2%;
  width: 43%;
  padding: 1%;
  display: none;
  /* width: 50dvh;  */
}

#waveform {
  width: 100%;
  margin-top: 20px;
}

.spinner {
  border: 6px solid var(--grey);
  border-top: 6px solid var(--dark-green);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
  left: 50%;
  display: block;
}

#explanation {
  margin-top: 3vh;
  width: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 1.5em;
  background: transparent;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Result Container Styling */
#result-container {
  display: none;
  flex-direction: column;
  padding: 3%;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0%;
}

#api-result {
  margin-top: 20px;
  white-space: pre-wrap; /* Keeps the format of JSON stringify */
  color: #555;
  font-family: monospace;
}

.copy-div {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.copy-btn {
  background-color: var(--white);
  border: none;
  cursor: pointer;
  color: var(--dark-green);
}

.copy-btn:focus {
  outline: none;
}
.canvases {
  margin-bottom: 5%;
}

@media screen and (max-width: 1200px) {
  .container-drag {
    position: absolute;
    left: 17%;
  }
  .form h5 {
    font-size: 10px;
  }
}
</style>
