<template>
  <div class="container-report">
    <h1 class="title">Report Generation</h1>
    <h5 class="error" id="error-drag">Please drop only audio files.</h5>
    <div id="top-container">
      <div id="drop-div">
        <div
          class="drop-area"
          @dragover.prevent
          @drop="handleDrop"
          @click="openFileDialog"
        >
          <p>Select files to process</p>
          <input
            type="file"
            id="file-input"
            style="display: none"
            @change="handleFileInputChange"
            accept="audio/*"
            multiple
          />
        </div>
      </div>
    </div>
    <div id="middle-container">
      <div class="form">
        <h5>Use case:</h5>
        <select v-model="selectedModel">
          <option
            v-for="(option, index) in models"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="form" id="endpointForm">
        <h5>Endpoint:</h5>
        <select v-model="selectedEndpoint">
          <option value="classify">classify</option>
          <!-- <option value="window">window</option> -->
        </select>
      </div>
    </div>
    <div id="bottom-container">
      <!-- Recognise button to trigger API-->
      <div class="recognise-container" @click="handleFileUpload">
        <!-- Load button if not loading for results -->
        <button v-if="!loading" type="submit">Recognise Sounds</button>
        <!-- Else load spinner -->
        <div v-else class="spinner"></div>
      </div>

      <!-- Result container -->
      <div id="result-container">
        <div class="copy-div">
          <button class="copy-btn" @click="copyText">
            <i class="bx bx-copy"></i>
          </button>
          <dialog id="custom-dialog">
            <p id="dialog-text">Result copied!</p>
          </dialog>
        </div>
        <p id="api-result"></p>
      </div>
    </div>

    <div v-for="(file, index) in files" :key="index" class="box">
      <DataRow
        :name="file.name"
        :tag="file.tag"
        :confidence="file.confidence"
        :id="index"
        :file_id="file.file_id"
        :file="file.file"
        @delete="deleteFile(id)"
      />
    </div>
    <button id="download" disabled="disabled" @click="downloadTableAsCSV">
      Download as CSV
    </button>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'
import axios from 'axios'
import DataRow from './DataRow.vue'

export default {
  name: 'DragComponent',
  components: {
    DataRow
  },
  data() {
    return {
      models: [],
      selectedEndpoint: 'classify',
      selectedModel: 'generic',
      selectedOption: '',
      files: [],
      loading: false,
      promises: [],
      current_plan_id: Number,
      current_token: ''
    }
  },
  created() {
    this.fetchdata()
  },
  methods: {
    fetchdata() {
      const requestData = {
        user_id: sessionStorage.getItem('id')
      }
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      // Make a GET request to retrieve plans details
      axiosInstance
        .get('/user/plans', {
          params: requestData
        })
        .then((response) => {
          this.plans = response.data
          for (const plan of this.plans) {
            this.current_plan_id = plan.id
            axiosInstance
              .get('/plan/models', {
                params: { plan_id: plan.id }
              })
              .then((response) => {
                for (const model of response.data) {
                  this.pushUniqueModel(model.name)
                }
              })
          }
          axiosInstance
            .get('/plan/tokens', {
              params: { plan_id: this.current_plan_id }
            })
            .then((response) => {
              console.log(response)
              for (const token of response.data) {
                this.current_token = token.token
              }
            })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    },
    pushUniqueModel(model) {
      // Check if the model with the same name already exists in this.models
      const existingModel = this.models.find(
        (existingModel) => existingModel === model
      )

      // If the model with the same name doesn't already exist, push the new model
      if (!existingModel) {
        this.models.push(model)
      }
    },
    handleDrop(event) {
      event.preventDefault()
      const errorMessage = document.getElementById('error-drag')
      errorMessage.style.display = 'none'
      const files = event.dataTransfer.files
      const audioFiles = this.filterAudioFiles(files)
      if (audioFiles.length > 0) {
        console.log('handleDrop: ', event)

        for (const file of files) {
          this.files.push({
            name: file.name,
            uploadCompletion: 0, // Assuming 0% upload completion initially
            tag: '-', // Placeholder values
            confidence: '-',
            audio: '-',
            file: file,
            src: URL.createObjectURL(file)
          })
        }
      } else {
        errorMessage.textContent = 'Only audio files are admitted!'
        errorMessage.style.display = 'flex'
      }
    },
    handleFileInputChange(event) {
      // Grab the latest audio file
      const files = event.target.files
      console.log('handleFileInputchange: ', event)
      // Error handling
      const errorMessage = document.getElementById('error-drag')
      errorMessage.style.display = 'none'

      for (const file of files) {
        this.files.push({
          name: file.name,
          uploadCompletion: 0, // Assuming 0% upload completion initially
          tag: '-', // Placeholder values
          confidence: '-',
          audio: '-',
          file: file,
          src: URL.createObjectURL(file)
        })
      }
    },
    openFileDialog() {
      document.getElementById('file-input').click()
    },
    async handleFileUpload(event) {
      // Prevent the default form submission
      event.preventDefault()
      const errorMessage = document.getElementById('error-drag')
      const downloadButton = document.getElementById('download')

      downloadButton.disabled = true

      // If no files have been uploaded
      if (this.files.length == 0) {
        errorMessage.textContent = 'No file uploaded'
        errorMessage.style.display = 'flex'
      } else {
        // Define the API endpoint to be called
        const apiEndpoint =
          'https://rissoai--sound-api-main.modal.run/classify' 
          /* ?token=' +
          this.current_token +
          '&model=' +
          this.selectedModel */
        var headers = {
          'token':this.current_token,
          'model':this.selectedModel
        }
        console.log(apiEndpoint)

        let promises = [] // Initialize an array to hold all the fetch promises

        // Iterate over each file
        this.files.forEach((file, index) => {
          // Only process the file if it hasn't been processed yet
          if (file.tag === '-') {
            // Set the spinner
            this.loading = true

            // Initialize FormData
            const formData = new FormData()
            formData.append('audio_files', file.file, file.name)
            formData.append('model_name', this.selectedModel)

            // Create a fetch promise and add it to the promises array
            const fetchPromise = fetch(apiEndpoint, {
              method: 'POST',
              headers: headers,
              body: formData
            }).then(async (response) => {
              const result = await response.json()
              if (response.status == 401) {
                // Show message for token exhausted
                errorMessage.textContent = 'Available tokens exhausted!'
                errorMessage.style.display = 'flex'
              } else if (response.status != 200) {
                // Show message for token exhausted
                errorMessage.textContent =
                  'Might be some problems with server, please try again!'
                errorMessage.style.display = 'flex'
              }

              // Update the file with the response data
              const prediction = result.results[0]
              console.log(prediction)
              this.files[index].confidence = prediction.tags[0].confidence
              this.files[index].tag = prediction.tags[0].tag
              this.files[index].file_id = prediction.id

              return result // Return result for further processing if needed
            })

            promises.push(fetchPromise)
          }
        })

        // Wait for all promises to resolve
        Promise.all(promises)
          .then(() => {
            this.loading = false // Set loading to false once all promises have resolved
            downloadButton.disabled = false
          })
          .catch((error) => {
            errorMessage.textContent =
              'Might be some problems with server, please try again!' + error
            errorMessage.style.display = 'flex'
            this.loading = false // Ensure loading is set to false even if there are errors
          })
      }
    },
    copyText() {
      // Get the text from the paragraph
      const text = document.getElementById('api-result').textContent
      const dialog = document.getElementById('custom-dialog')
      // Use the Clipboard API to copy the text

      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or change the icon to indicate success
          dialog.showModal()
          document.addEventListener('click', (event) => {
            if (event.target === dialog) {
              dialog.close()
            }
          })
        })
        .catch((err) => {
          alert('Failed to copy text: ', err)
        })
    },
    downloadTableAsCSV() {
      // Create CSV headers
      // const csvHeaders = "File,Tag,Confidence,Audio\n";
      const csvHeaders = 'File,Tag,Confidence\n'

      // Convert data to CSV rows
      const csvRows = this.files
        .map((file) => {
          // Assuming file.src is the URL to the audio, you might not include it in the CSV directly
          // Alternatively, you can just include the name or a description
          //const audioDescription = "Audio File"; // Or use `file.src` if you want the URL in the CSV
          //return `"${file.name}","${file.tag}","${file.confidence}","${audioDescription}"`;
          return `"${file.name}","${file.tag}","${file.confidence}"`
        })
        .join('\n')

      // Combine headers and rows
      const csvString = csvHeaders + csvRows

      // Create a Blob with the CSV data
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })

      // Create a link to download the Blob
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // Feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'results.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    filterAudioFiles(files) {
      return Array.from(files).filter((file) => {
        return file.type.startsWith('audio/')
      })
    },
    deleteFile(id) {
      this.files.splice(id, 1)
    },
    clickRow($event, index) {
      console.log(index)
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

#top-container {
  display: inline-flex;
  height: 10%;
  width: 92%;
  margin: 4%;
  align-items: center;
  justify-content: center;
  font-family: 'Manrope', sans-serif;
  gap: 10%;
}
#middle-container {
  width: 40%;
  height: 3%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10%;
  font-family: 'Manrope', sans-serif;
}

#bottom-container {
  display: contents;
  height: 40%;
  width: 100%;
  align-items: center;
}
.form {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.form h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}

.title {
  margin-top: 3.5%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--highlight-green);
}

i {
  font-size: 24px;
}
.error {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  color: crimson;
  width: 100%;
  margin: 0%;
  text-align: center;
  line-height: 1.5em;
  display: none;
  margin-top: 5%;
}

#drop-div {
  display: flow;
  width: 25%;
  min-width: 200px;
  vertical-align: middle;
  text-align: center;
  margin: 8%;
}
.drop-area {
  display: flex;
  /* position: relative; */
  border-radius: 50px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed var(--dark-grey);
  /* width: 40%; */
  cursor: pointer;
  margin: auto;
  /* padding: 25%; */
}

.drop-area:hover {
  border: 2px dashed var(--dark-green);
  transition: 0.6s;
}

.drop-area p {
  margin: 20px;
}
dialog {
  position: relative;
  left: 55%;
  top: 79%;
  background: #f5fcf5;
  border: 0px;
  border-radius: 12px;
  font-size: 14px;
  width: 110px;
  height: 28px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
}
#dialog-text {
  position: relative;
  margin: 3%;
}

.container-report {
  position: relative;
  background-color: transparent;
  top: 0%;
  width: 83%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
  border-left: 2px solid var(--grey);
  background: linear-gradient(to right, var(--white), var(--light-green));
}

/* Form Styling */
#upload-form {
  /* padding: 20px; */
  border-radius: 8px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.recognise-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 5vh;
}

/* Button Styling */
.recognise-container button {
  background-color: var(--dark-green);
  color: var(--white);
  padding: 15px 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  margin-bottom: 10%;
}

.recognise-container button:hover {
  background-color: var(--light-green);
  color: var(--dark-green);
}

#name-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

#player {
  margin-top: 2%;
  width: 43%;
  padding: 1%;
  /* width: 50dvh;  */
}

#waveform {
  width: 100%;
  margin-top: 20px;
}

.spinner {
  border: 6px solid var(--grey);
  border-top: 6px solid var(--dark-green);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Result Container Styling */
#result-container {
  display: none;
  flex-direction: column;
  padding: 3%;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0%;
}

#api-result {
  margin-top: 20px;
  white-space: pre-wrap; /* Keeps the format of JSON stringify */
  color: #555;
  font-family: monospace;
}

.copy-div {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.copy-btn {
  background-color: var(--white);
  border: none;
  cursor: pointer;
  color: var(--dark-green);
}

.copy-btn:focus {
  outline: none;
}
.canvases {
  margin-bottom: 5%;
}

#container-results-table {
  width: 80%;
  margin-top: 30px;
  display: flex;
  align-content: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

select {
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
}

option {
  padding: 4px;
  border-radius: 4px;
}

#results-table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0 10px;
  margin-top: 2%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border-bottom: 2px solid #f0f0f0;
}
tr {
  padding: 2%;
}
th {
  background-color: var(--light-green);
  border-top: 2px solid var(--dark-green);
  /* color: var(--light-green);
    background-color: var(--dark-green); */
}

#upload-table td {
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  overflow: hidden;
  padding: 2%;
}
#file-col {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#audio-col {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#download {
  margin-top: 5vh;
  background-color: var(--dark-green);
  color: var(--white);
  padding: 15px 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  margin-bottom: 10%;
}

#download:hover {
  background-color: var(--light-green);
  color: var(--dark-green);
}

#download:disabled {
  background: var(--grey);
  color: #555;
  cursor: not-allowed;
}

.box {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

/* #upload-table {
    width: 50%;
    border-collapse: separate; 
    border-spacing: 0 10px; 
    table-layout: fixed;
    text-align: left;
    margin-top: 5%;
}

#upload-table th, #upload-table td {
    padding: 10px;
    border-bottom: 2px solid #f0f0f0; 
    background-color: #fff; 
}

#upload-table th {
    background-color: var(--light-green);
    border-top: 2px solid var(--dark-green);
    border-radius: 10px;
}

#upload-table td {
    border-right: 1px solid #f0f0f0;
}

#upload-table td:last-child {
    border-right: none;
} */
@media screen and (max-width: 1200px) {
  .container-report {
    position: absolute;
    left: 17%;
  }

  th {
    font-size: 11px;
  }
  tr {
    font-size: 9px;
  }
  th,
  td {
    display: table-cell;
    padding: 2%;
  }
}

@media screen and (max-width: 800px) {
  .container-report {
    position: absolute;
    left: 17%;
  }

  th {
    font-size: 10px;
  }
  tr {
    font-size: 9px;
    padding: 2px;
  }
  th,
  td {
    display: table-cell;
  }

  #top-container {
    margin: 14%;
  }

  .form h5 {
    font-size: 10px;
  }
  #audio-col {
    text-align: right;
  }
}
</style>
