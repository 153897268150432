<template>
  <div class="container-drag">
    <h1 class="title">Demo</h1>
    <h5 class="error" id="error-drag"></h5>
    <div id="instructions-demo">
      Experience the power of our <span class="emph">sound recognition model</span>!
      <br><br>
      Dive in by selecting one of the files below. Watch eagerly as the magic unfolds on the right, revealing the <span class="emph">precise identification of sounds</span>.
    </div>
    <div id="top-class-container">
      <div id="top-container">
        <container id="player">
          <div id="name-container">
            <p id="file-status"></p>
          </div>
          <div id="waveform"></div>
        </container>
        <div id="buttons-select-audio">
          <button class="select-audio" @click="handleFileInputChangeButton(0, true)" id="select-audio-0">Car engine</button>
          <button class="select-audio" @click="handleFileInputChangeButton(1, true)" id="select-audio-1">Phone ringing</button>
          <button class="select-audio" @click="handleFileInputChangeButton(2, true)" id="select-audio-2">People murmuring</button>
        </div>
        <MicrophoneComponent :exit-intro="this.intro" @recording="uploadRecording" />
      </div>
      <div id="result-container">
        <div class="copy-div">
          <div v-if="isPredicting" id="spinner" class="spinner"></div>
        </div>
        <div id="result-div" class="result-div-style" style="display:flex; width:100%; height:100%; position:absolute; margin:0px; padding:0px;">
          <div id="image-div" class="image-div-style" style="width:45%; height:100%; display:flex; flex-direction:column; justify-content:center; align-items:center; white-space:nowrap;">
            <font-awesome-icon v-if="predictedTag === 'Telephone'" :icon="['fas', 'phone']" size="4x"/>
            <font-awesome-icon v-else-if="predictedTag === 'Vehicle'" :icon="['fas', 'car-side']" size="4x"/>
            <font-awesome-icon v-else-if="predictedTag === 'Speech'" :icon="['far', 'comments']" size="4x"/>
            <font-awesome-icon v-else-if="predictedTag !== null" :icon="['fas', 'music']" size="4x"/>
            <div v-show="predictedTag !== null" class="tag-result" style="margin-top:15px;">
              <span class="emph" id="tag-result-prediction"></span>
            </div>
          </div>
          <div id="tag-div" class="tag-div-style" style="width:55%; height:100%">
            <div id="result-div-2" class="result-div-style" style="display:flex; width:100%; height:100%; position:absolute; margin:0px; padding:0px;">
              <div id="confidence-div" class="confidence-div-style" style="width:50%; height:100%; display:flex; flex-direction:column; justify-content:center; align-items:center; white-space:nowrap;">
                <div v-show="predictedTag !== null" id="reliability-score" class="reliability-score-style" style="font-size: 4rem; font-weight:bold; text-align:center;"></div>
                <div>
                  <span v-show="predictedTag !== null" class="emph">Reliability</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="middle-container">
      <div class="form">
        <h5>Use case:</h5>
        <select v-model="selectedModel">
          <option v-for="(option, index) in models" :key="index" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="form" id="endpointForm">
        <h5>Endpoint:</h5>
        <select v-model="selectedEndpoint">
          <option value="classify">classify</option>
        </select>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import WaveSurfer from 'wavesurfer.js'
  import axios from 'axios'
  import '@fortawesome/fontawesome-free/css/all.css'
  import introJs from 'intro.js';
  import 'intro.js/introjs.css'; // Import the CSS for intro.js
  import MicrophoneComponent from './microphone.vue'

  export default {
    name: 'DemoComponent',
    components: {
      MicrophoneComponent
    },
    data() {
      return {
        models: [],
        current_plan_id: Number,
        current_token: '',
        selectedEndpoint: 'classify',
        selectedModel: 'generic',
        token: '',
        isPredicting: false,
        predictedTag: null,
        demo: true,
        files: [],
        calls: 0,
        data: null
      }
    },
    created() {
      this.fetchdata()
    },
    async mounted(){
        // Mount the html with first audio file selected
        this.handleFileInputChangeButton(0, false)
        // Remove tags and endpoint
        document.getElementById('middle-container').style.display = 'none'
        // Start intro
        if (this.demo) await this.firstIntro()
    },
    methods: {
      handleButtonClick() {
        console.log(this.selectedOption)
      },
      async firstIntro() {
        this.intro = introJs();
        this.intro.setOptions({
          steps: [
            {
              intro: "Welcome to Risso demo! Here we will show you how the AI can be leveraged for noise event detection.",
            },
            {
              intro: "Press on a button to select an audio sample from different noise sources and trigger our AI noise-detection application!",
              element: document.getElementById('buttons-select-audio'),
            },
            {
              intro: "The AI engine has started! You will see the output right here!.",
              element: document.getElementById('result-container')
            },
            {
              intro: "This is the event predicted by the AI",
              element: document.getElementById('image-div')
            },
            {
              intro: "This percentage represents the reliability of the AI prediction!",
              element: document.getElementById('tag-div')
            },
            {
              intro: "Try yourself with the microphone! Keep the button pressed to record a sample.",
              element: document.getElementById('microphone-container')
            },
          ],
          showButtons: false, // This keeps the skip button visible
          showStepNumbers: true,
        }).onexit(() => {this.demo = false;});

        this.intro.start();
        await this.nextStepDelay(5000)
      },
      async nextStepDelay(delay){
        // Go to next step in a given amount of time
        setTimeout(() => {if (this.demo) this.intro.nextStep()}, delay)
      },
      async fetchdata() {
        const requestData = {
          user_id: sessionStorage.getItem('id')
        }
        const axiosInstance = axios.create({
          baseURL: 'https://auth.risso.ai/',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
            'Content-Type': 'application/json'
          }
        })
        // Make a GET request to retrieve plans details
        axiosInstance
          .get('/user/plans', {
            params: requestData
          })
          .then((response) => {
            this.plans = response.data
            const current_plan = this.plans.reduce((prev, current) => {
              return current.is_active ? current : prev
            }, null)
  
            this.current_plan_id = current_plan.id
            axiosInstance
              .get('/plan/models', {
                params: { plan_id: this.current_plan_id }
              })
              .then((response) => {
                for (const model of response.data) {
                  this.pushUniqueModel(model.name)
                }
              })
  
            // Make a GET request to retrieve tokens details
            axiosInstance
              .get('/plan/tokens', {
                params: { plan_id: this.current_plan_id }
              })
              .then((response) => {
                console.log(response)
                for (const token of response.data) {
                  this.current_token = token.token
                }
              })
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
      },
      pushUniqueModel(model) {
        // Check if the model with the same name already exists in this.models
        const existingModel = this.models.find(
          (existingModel) => existingModel === model
        )
  
        // If the model with the same name doesn't already exist, push the new model
        if (!existingModel) {
          this.models.push(model)
        }
      },
      handleDrop(event) {
        event.preventDefault()
        const waveform = document.getElementById('waveform')
        while (waveform.firstChild) {
          waveform.removeChild(waveform.firstChild)
        }
        const errorMessage = document.getElementById('error-drag')
        const files = event.dataTransfer.files
        const audioFiles = this.filterAudioFiles(files)
        if (audioFiles.length > 0) {
          this.loadContent(audioFiles[0])
        } else {
          // Show message to warn only audio files are accepted
          errorMessage.textContent = 'Only audio files are admitted!'
          errorMessage.style.display = 'flex'
        }
      },
      async handleFileInputChange(event) {
        // Get element to show error messages and sat it to not displayed
        const errorMessage = document.getElementById('error-drag')
        errorMessage.style.display = 'none'
  
        // Remove Waveform content to update it with another file
        const waveform = document.getElementById('waveform')
        while (waveform.firstChild) {
          waveform.removeChild(waveform.firstChild)
        }
  
        // Get uploaded files
        const files = event.target.files
        const audioFiles = this.filterAudioFiles(files)
        if (audioFiles.length == 0) {
          errorMessage.textContent = 'Only audio files are admitted!'
          errorMessage.style.display = 'flex'
        }
  
        // Load content of file
        this.loadContent(audioFiles[0])
      },
      async handleFileInputChangeButton(index, startAiPrediction) {

        // Get element to show error messages and sat it to not displayed
        const errorMessage = document.getElementById('error-drag')
        errorMessage.style.display = 'none'
  
        // Remove Waveform content to update it with another file
        const waveform = document.getElementById('waveform')
        while (waveform.firstChild) {
          waveform.removeChild(waveform.firstChild)
        }
  
        // Get files
        const file_1 = "https://rissoai-public-bucket.s3.eu-west-1.amazonaws.com/risso-demo/car-engine.mp3"
        const file_2 = "https://rissoai-public-bucket.s3.eu-west-1.amazonaws.com/risso-demo/phone.mp3" 
        const file_3 = "https://rissoai-public-bucket.s3.eu-west-1.amazonaws.com/risso-demo/indoor-adult-murmur.mp3" 

        const audioFiles = [file_1, file_2, file_3]
        if (audioFiles.length == 0) {
          errorMessage.textContent = 'Only audio files are admitted!'
          errorMessage.style.display = 'flex'
        }
  
        // Load content of file
        this.loadContentButton(audioFiles[index], index)

        // Color the border of selected button
        audioFiles.forEach((value, i) => {
            
            // Take the button
            const buttonIndex = document.getElementById(`select-audio-${i}`)

            // If index is i
            if (index === i){
                // Add class 'selected-audio'
                buttonIndex.classList.add('selected-audio')
            } else {
                // Remove class 'selected-audio'
                buttonIndex.classList.remove('selected-audio')
            }
        })

      // ML noise prediction
      if (startAiPrediction) {

        // Next step as soon as the button is pressed
        await this.nextStepDelay(100)
        
        // Launch noise detection 
        await this.handleFileUpload()

        // As soon as the next step finished go ahead with explanations
        await this.nextStepDelay(100)

        // Do last step
        await this.nextStepDelay(4000)

        // Present recording feature
        await this.nextStepDelay(8000)
      }

      // Update calls
      this.calls++
      },
      openFileDialog() {
        document.getElementById('file-input').click()
      },
      loadContent(file) {
        if (!file) {
          return
        }
        const fileStatus = document.getElementById('file-status')
        const errorMessage = document.getElementById('error-drag')
        const wavesurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#8ce5aa',
          progressColor: '#2d4e4e',
          barWidth: 0,
          normalize: true,
          mediaControls: true
        })
  
        if (file) {
          this.files.push(file)
          fileStatus.textContent = file.name
          const url = URL.createObjectURL(file)
          wavesurfer.load(url)
          document.getElementById('result-container').style.display = 'flex'
  
          // Show player when fils are uploaded
          const player = document.getElementById('player')
          const container = document.getElementById('top-container')
          player.style.display = 'inline'
          container.style.display = 'inline-flex'
        } else {
          errorMessage.textContent = 'No file uploaded'
          errorMessage.style.display = 'content'
          wavesurfer.empty()
        }
      },
        async createFileFromUrl(url, fileName) {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                // Here, we assume the content is a blob (e.g., binary data like an image, audio, etc.)
                const data = await response.blob();

                // Create a file from the Blob
                const file = new File([data], fileName, {
                    type: data.type, // This takes the MIME type from the fetched data
                });

                return file;
            } catch (error) {
                console.error("Failed to create a file:", error);
            }
        },
        loadContentButton(file, index) {
        if (!file) {
          return
        }
        const fileStatus = document.getElementById('file-status')
        const errorMessage = document.getElementById('error-drag')
        const wavesurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#8ce5aa',
          progressColor: '#2d4e4e',
          barWidth: 0,
          normalize: true,
          mediaControls: true
        })
  
        if (file) {
          const filenames = ["Car engine", "Phone ringing", "People murmuring"]
          fileStatus.textContent = filenames[index]
          fileStatus.textContent = ""
          this.files = [file]
          //this.files.push(this.createFileFromUrl(file, file))
          wavesurfer.load(file)
          document.getElementById('result-container').style.display = 'flex'
  
          // Show player when fils are uploaded
          const player = document.getElementById('player')
          const container = document.getElementById('top-container')
          player.style.display = 'inline'
          container.style.display = 'inline-flex'
        } else {
          errorMessage.textContent = 'No file uploaded'
          errorMessage.style.display = 'content'
          wavesurfer.empty()
        }
      },
      async uploadRecording(blob) {
      const waveform = document.getElementById('waveform');
      while (waveform.firstChild) {
        waveform.removeChild(waveform.firstChild);
      }

      const fileStatus = document.getElementById('file-status');
      const errorMessage = document.getElementById('error-drag');
      errorMessage.style.display = 'none';

      const wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#8ce5aa',
        progressColor: '#2d4e4e',
        barWidth: 0,
        normalize: true,
        mediaControls: true
      });

      try {
        if (!blob) {
          throw new Error('Blob is undefined');
        }

        console.log('Blob type:', blob.type);
        console.log('Blob size:', blob.size);
        console.log('Blob object:', blob);

        fileStatus.textContent = 'Recorded Audio';
        const url = URL.createObjectURL(blob);
        console.log('Object URL:', url);
        wavesurfer.load(url);
        document.getElementById('result-container').style.display = 'flex';

        // Show player when files are uploaded
        const player = document.getElementById('player');
        const container = document.getElementById('top-container');
        player.style.display = 'inline';
        container.style.display = 'inline-flex';

        // Call the API with the recorded audio blob
        const formData = new FormData();
        formData.append('audio_files', blob, 'recorded_audio.wav');
        formData.append('model_name', this.selectedModel);

        const apiEndpoint = 'https://rissoai--sound-api-main.modal.run/classify';
        const headers = {
          'token': this.current_token,
          'model': this.selectedModel
        };

        // Set the spinner
        this.isPredicting = true;

        // Call the API
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: headers,
          body: formData
        });

        // Grab prediction results
        const result = await response.json();

        if (response.status === 401) {
          // Show message for token exhausted
          errorMessage.textContent = 'Available tokens exhausted!';
          errorMessage.style.display = 'flex';
        } else if (response.status !== 200) {
          // Show message for server problems
          errorMessage.textContent = 'Might be some problems with the server, please try again!';
          errorMessage.style.display = 'flex';
        } else {
          // Update predicted tag
          this.predictedTag = result.results[0].tags[0].tag;

          // Write tag to result
          document.getElementById('tag-result-prediction').innerHTML = this.predictedTag;

          // Set the content of results
          document.getElementById('reliability-score').innerHTML = result.results[0].tags[0].confidence;

          console.log(this.predictedTag)
          // Remove spinner
          this.isPredicting = false;
        }
      } catch (error) {
        console.error('Error loading recorded audio:', error);
        errorMessage.textContent = 'Error loading recorded audio';
        errorMessage.style.display = 'flex';
        wavesurfer.empty();
      }
    },
      async handleFileUpload() {
  
        // Hide response screen
        this.predictedTag = null

        // Clear screen
        document.getElementById('')

        //event.preventDefault()
        const errorMessage = document.getElementById('error-drag')
        const audio_files = this.files
        if (audio_files.length == 0) {
          errorMessage.textContent = 'No file uploaded'
          errorMessage.style.display = 'flex'
        } else {
          // Define the API endpoint to be called
          
          const apiEndpoint = 'https://rissoai--sound-api-main.modal.run/classify'
            /* ?token=' +
            this.current_token +
            '&model=' +
            this.selectedModel */
          var headers = {
            'token':this.current_token,
            'model':this.selectedModel
          }
          // Init form data
          const formData = new FormData()
  
          // Get the files into the form
          for (const file of audio_files) {

            const blobAudio = await fetch(file).then(res => res.blob()).catch(error => console.log(error))
            formData.append('audio_files', blobAudio, file) // Append each file to formData
          }
  
          // Append data to the FormData object
          formData.append('model_name', this.selectedModel)
  
          // Try calling the API and catch any errors
          try {
            
            // Set the spinner
            this.isPredicting = true

            // Call the API
            const response = await fetch(apiEndpoint, {
              method: 'POST',
              headers:headers,
              body: formData
            })
  
            // Grab prediction results
            const result = await response.json()
  
            if (response.status == 401) {
              // Show message for token exhausted
              errorMessage.textContent = 'Available tokens exhausted!'
              errorMessage.style.display = 'flex'
            } else if (response.status != 200) {
              // Show message for token exhausted
              errorMessage.textContent =
                'Might be some problems with server, please try again!'
              errorMessage.style.display = 'flex'
            } else {

              // Update predicted tag
              this.predictedTag = result.results[0].tags[0].tag
              
              // Write tag to result
              document.getElementById('tag-result-prediction').innerHTML = this.predictedTag

              // Set the content of results
              document.getElementById('reliability-score').innerHTML = result.results[0].tags[0].confidence
              
              // Remove spinner
              this.isPredicting = false              
            }
          } catch (error) {
            errorMessage.textContent = "There are some problems with the server. Please, try again later."
            errorMessage.style.display = 'flex'
          } 
        }
      },
      filterAudioFiles(files) {
        return Array.from(files).filter((file) => {
          return file.type.startsWith('audio/')
        })
      }
    }
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');
  
  #top-class-container{
    display: flex;
    height: 10%;
    width: 100%;
    margin: 4% 0%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-family: 'Manrope', sans-serif;
  }

  #top-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Manrope', sans-serif;
}
  #middle-container {
    width: 40%;
    height: 3%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    font-family: 'Manrope', sans-serif;
    gap: 10%;
    margin-bottom: 5%;
    justify-content: center;
  }
  .form {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  
  .form h5 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
  .title {
    margin-top: 3.5%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
  }
  
  a {
    text-decoration: none;
    color: var(--highlight-green);
  }
  
  i {
    font-size: 24px;
  }
  .error {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    color: crimson;
    width: 100%;
    margin: 0%;
    text-align: center;
    line-height: 1.5em;
    display: none;
    margin-top: 5%;
  }
  
  #drop-div {
    display: flow;
    width: 25%;
    min-width: 200px;
    vertical-align: middle;
    text-align: center;
    margin: 8%;
  }
  .drop-area {
    display: flex;
    /* position: relative; */
    border-radius: 50px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px dashed var(--dark-grey);
    /* width: 40%; */
    cursor: pointer;
    margin: auto;
    /* padding: 25%; */
  }
  .drop-area:hover {
    border: 2px dashed var(--dark-green);
    transition: 0.6s;
  }
  
  .form h5[data-v-2f693578] {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
  select {
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
  }
  .drop-area p {
    margin: 20px;
  }
  
  dialog {
    position: relative;
    left: 55%;
    top: 79%;
    background: #f5fcf5;
    border: 0px;
    border-radius: 12px;
    font-size: 14px;
    width: 110px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    font-family: 'Manrope', sans-serif;
  }
  #dialog-text {
    position: relative;
    margin: 3%;
  }
  
  .container-drag {
    position: relative;
    background-color: transparent;
    top: 0%;
    width: 83%;
    min-height: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    --light-green: #f5fcf5;
    --medium-green: #73b170;
    --dark-green: #2d4e4e;
    --highlight-green: #8ce5aa;
    --yellow: #cfff04;
    --white: #fff;
    --grey: #eee;
    --dark-grey: #bdbdbd;
    border-left: 2px solid var(--grey);
    background: linear-gradient(to right, var(--white), var(--light-green));
  }
  
  /* Form Styling */
  #upload-form {
    /* padding: 20px; */
    border-radius: 8px;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .recognise-container {
    align-items: center;
    justify-content: center;
  }
  
  /* Button Styling */
  .recognise-container button {
    background-color: var(--dark-green);
    color: var(--white);
    padding: 15px 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 1s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    margin-bottom: 10%;
    margin-top: 10%;
  }
  
  .recognise-container button:hover {
    background-color: var(--light-green);
    color: var(--dark-green);
  }
  
  #name-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  #player {
    margin-top: 2%;
    width: 43%;
    padding: 1%;
    display: none;
    /* width: 50dvh;  */
  }
  
  #waveform {
    width: 100%;
    margin-top: 20px;
  }
  
  .spinner {
    border: 5px solid var(--grey);
    border-top: 5px solid var(--dark-green);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
    animation: spin 0.8s ease-in-out infinite;
    position: absolute;
  }
  
  #explanation {
    margin-top: 3vh;
    width: 50%;
    text-align: center;
    font-size: 16px;
    line-height: 1.5em;
    background: transparent;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  #buttons-select-audio{
    display: flex;
    width: 40%;
    justify-content: space-around;
    align-items: center;
  }

  .select-audio{
    padding: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 2px solid var(--dark-green);
    border-radius: 5px;
    font-weight: bold;
    color: var(--dark-green)
  }

  .select-audio:hover{
    scale: 1.1;
    transition: all 0.3s ease-in-out;
  }

  .selected-audio{
    border: 3px solid var(--highlight-green);
  }

  #instructions-demo{
    padding: 10px 22%;
    font-weight: lighter;
    color: var(--dark-green);
    font-style: italic;
    margin-top: 5px;
}

.emph{
  font-weight: bold;
  color: var(--medium-green)
}

.introjs-relativePosition {
  position: initial !important;
}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Result Container Styling */
  #result-container {
    display: flex;
    width: 40%;
    height: 220px;
    padding: 10px;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 0%;
    transform: translateX(-50%);
  }
  
  .copy-div {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: end;
  }

  .copy-btn:focus {
    outline: none;
  }
  .canvases {
    margin-bottom: 5%;
  }
  
  @media screen and (max-width: 1200px) {
    .container-drag {
      position: absolute;
      left: 17%;
    }
    .form h5 {
      font-size: 10px;
    }
  }
  </style>
  