<template>
  <div id="microphone-container">
    <button @mousedown="startRecording" @mouseup="stopRecording" id="microphone-button" :style="{'color': '#2d4e4e', 'border-color': '#2d4e4e'}">
      <i class="fa-solid fa-microphone" :style="{'color': '#73b170'}"></i>
    </button>
    <div id="progress-container">
      <div id="progress-bar" :style="{ width: `${progress}%` }"></div>
      <span v-if="isRecording">{{ formattedTime }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicrophoneComponent',
  props: ['exitIntro'],
  data() {
    return {
      micColor: 'blue',
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      startTime: null,
      elapsedTime: 0,
      progress: 0,
      recordingInterval: null,
    };
  },
  computed: {
    formattedTime() {
      const milliseconds = this.elapsedTime % 1000;
      const seconds = Math.floor((this.elapsedTime / 1000) % 60);
      const minutes = Math.floor((this.elapsedTime / (1000 * 60)) % 60);
      const pad = (num, size) => ('000' + num).slice(size * -1);
      return `${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(milliseconds, 3)}`;
    }
  },
  methods: {
    async startRecording() {
      if (this.isRecording) return;

      try {
        // Exit from the intro
        this.$props?.exitIntro.exit(); 
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = () => {
          this.emitRecording();
          clearInterval(this.recordingInterval);
          this.resetProgress();
        };
        this.audioChunks = [];
        this.mediaRecorder.start();
        this.startTime = Date.now();
        // Change color of the mic
        const micButton = document.querySelector('.fa-microphone')
        micButton.style.color = '#2d4e4e'
        const borderButton = document.getElementById('microphone-button');
        borderButton.style.borderColor = '#73b170';
        borderButton.style.backgroundColor = '#cfff04';
        this.isRecording = true;
        this.startProgress();
      } catch (err) {
        console.error('Error accessing microphone:', err);
      }
    },
    stopRecording() {
      if (!this.isRecording) return;

      this.mediaRecorder.stop();
      const micButton = document.querySelector('.fa-microphone')
      micButton.style.color = '#73b170'
      const borderButton = document.getElementById('microphone-button');
      borderButton.style.borderColor = '#2d4e4e';
      borderButton.style.backgroundColor = 'transparent';

      this.isRecording = false;
    },
    emitRecording() {
      const blob = new Blob(this.audioChunks, { type: 'audio/wav' });
      console.log('Emitting Blob:', blob);
      this.$emit('recording', blob);
    },
    startProgress() {
      this.recordingInterval = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime;
        this.progress = (this.elapsedTime / 10000) * 100; // Update progress for up to 10 seconds

        if (this.elapsedTime >= 10000) { // Stop recording after 10 seconds
          this.stopRecording();
        }
      }, 100);
    },
    resetProgress() {
      this.elapsedTime = 0;
      this.progress = 0;
    }
  }
}
</script>

<style scoped>
#microphone-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

#microphone-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

#microphone-button:hover {
  scale: 1.05;
  transition: all 0.2s ease-in-out;
}

#progress-container {
  display: flex;
  align-items: center;
  height: 16px;
  width: 200px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 10px;
  position: relative;
}

#progress-bar {
  background-color: #4caf50;
  height: 100%;
  transition: width 0.2s ease-in-out;
}

#progress-container span {
  position: absolute;
  right: 10px;
  font-size: 14px;
  color: #000;
  z-index: 1;
}
</style>
