<template>
  <div class="container-plans-page">
    <h1 class="title">Choose your plan</h1>
    <span></span>
    <div class="container-plans">
      <div class="column noHover">
        <div id="FREE" class="pricing-card basic">
          <div class="pricing-header">
            <span class="plan-title">FREE</span>
            <div class="price-circle">
              <span class="price-title"> <small>€</small><span>0</span> </span>
              <span class="info">/ Month</span>
            </div>
          </div>
          <div class="badge-box">
            <span>Free Trial</span>
          </div>
          <ul>
            <li><strong>20</strong> API Calls</li>
            <li><strong>Drag And Drop</strong> Interface</li>
            <li>
              <s>Custom Models</s>
            </li>
            <li>
              <s>Speech To Text</s>
            </li>
            <li>
              <s>Report Generation</s>
            </li>
          </ul>
          <div class="buy-button-box">
            <a
              v-if="current_price == 'FREE'"
              href="#"
              :disabled="true"
              class="buy-now"
              >IN USE</a
            >
            <a
              v-if="current_price != 'FREE'"
              href="#"
              :disabled="true"
              class="buy-now"
              >EXPIRED</a
            >
          </div>
        </div>
      </div>
      <div class="column">
        <div id="BASIC" class="pricing-card echo">
          <div class="pricing-header">
            <span class="plan-title">BASIC</span>
            <div class="price-circle">
              <span class="price-title">
                <small>€</small><span>140</span>
              </span>
              <span class="info">/ Month</span>
            </div>
          </div>
          <div class="badge-box">
            <span>Low Volume</span>
          </div>
          <ul>
            <li><strong>1000</strong> API Calls</li>
            <li><strong>Drag And Drop</strong> Interface</li>
            <li>
              <s>Custom Models</s>
            </li>
            <li>
              <s>Speech To Text</s>
            </li>
            <li>
              <s>Report Generation</s>
            </li>
          </ul>
          <div class="buy-button-box">
            <a
              v-if="current_price == 'BASIC'"
              href="#"
              :disabled="true"
              class="buy-now"
              >IN USE</a
            >
            <a
              v-if="current_price != 'BASIC'"
              href="#"
              class="buy-now"
              @click="buy('BASIC')"
              >BUY NOW</a
            >
          </div>
        </div>
      </div>
      <div class="column">
        <div id="PRO" class="pricing-card pro">
          <div class="popular">POPULAR</div>
          <div class="pricing-header">
            <span class="plan-title">PRO</span>
            <div class="price-circle">
              <span class="price-title">
                <small>€</small><span>350</span>
              </span>
              <span class="info">/ Month</span>
            </div>
          </div>
          <div class="badge-box">
            <span>High Volume</span>
          </div>
          <ul>
            <li><strong>10'000</strong> API Calls</li>
            <li><strong>Drag And Drop</strong> Interface</li>
            <li><strong>Custom</strong> Models</li>
            <li>
              <s>Speech To Text</s>
            </li>
            <li>
              <s>Report Generation</s>
            </li>
          </ul>
          <div class="buy-button-box">
            <a
              v-if="current_price == 'PRO'"
              href="#"
              :disabled="true"
              class="buy-now"
              >IN USE</a
            >
            <a
              v-if="current_price != 'PRO'"
              href="#"
              class="buy-now"
              @click="buy('PRO')"
              >BUY NOW</a
            >
          </div>
        </div>
      </div>
      <div class="column">
        <div id="BUSINESS" class="pricing-card business">
          <div class="pricing-header">
            <span class="plan-title">ENTERPRISE</span>
            <div class="price-circle">
              <span class="price-title">
                <small>€</small><span>600</span>
              </span>
              <span class="info">/ Month</span>
            </div>
          </div>
          <div class="badge-box">
            <span>Generative AI</span>
          </div>
          <ul>
            <li><strong>100'000</strong> API Calls</li>
            <li><strong>Drag And Drop</strong> Interface</li>
            <li><strong>Custom</strong> Models</li>
            <li>
              <strong>Speech To Text</strong>
            </li>
            <li><strong>Report</strong> Generation</li>
          </ul>
          <div class="buy-button-box">
            <a
              v-if="current_price == 'ENTERPRISE'"
              href="#"
              :disabled="true"
              class="buy-now"
              >IN USE</a
            >
            <a
              v-if="current_price != 'ENTERPRISE'"
              href="#"
              class="buy-now"
              @click="buy('ENTERPRISE')"
              >BUY NOW</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'
import axios from 'axios'
export default {
  name: 'PlansComponent',
  data() {
    return {
      current_price: '',
      is_active: Boolean
    }
  },
  created() {
    // fetchdata() is called when this component is loaded
    this.fetchdata()
  },
  methods: {
    buy(plan_type) {
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      axiosInstance
        .post('/plan/create', {
          user_id: sessionStorage.getItem('id'),
          price: plan_type
        })
        .then((result) => {
          window.location.href = result.data['URL']
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    async fetchdata() {
      // Get User info from Auth API to get the current plan
      const requestData = {
        user_id: sessionStorage.getItem('id')
      }
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })

      // Make a GET request to retrieve plans details
      await axiosInstance
        .get('/user/plans', {
          params: requestData
        })
        .then((response) => {
          var plans = response.data
          const current_plan = plans.reduce((prev, current) => {
            return current.is_active ? current : prev
          }, null)
          this.is_active = current_plan['is_active']
          this.current_price = current_plan['type']
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })

      // Set current plan style as it is hovered
      const current_plan = document.getElementById(this.current_price)
      if (current_plan) {
        current_plan.classList.add('pricing-card-hover')
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

.noHover {
  pointer-events: none;
}

h2 {
  margin: 0;
}
/* Body */
/* $body-background: linear-gradient(to left, #a55eea, #45aaf2);
$radius : 20px;
$table-padding : 20px;
$break-small: 320px;
$break-large: 1200px;

$primary: #4b7bec;
$echo: #f7b731;
$pro: #26de81;
$business: #a55eea; */

/* @mixin transition($time, $property) {
  -webkit-transition: $time $property ease;
  -ms-transition: $time $property ease;
  transition: $time $property ease;
} */

/* Card Themes - Start */
.container-plans-page {
  position: relative;
  width: 83%;
  margin: 0;
  min-height: 100%;
  height: fit-content;
  text-align: center;
  --radius: 20px;
  --break-small: 320px;
  --break-large: 1200px;
  --stable-padding: 20px;
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
  border-left: 2px solid var(--grey);
  background: linear-gradient(to right, var(--white), var(--light-green));
}
.container-plans {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 2%;
  gap: 4%;
  border: 2%;
}
.buy-now {
  margin-bottom: 5%;
}
.title {
  margin-top: 3.5%;
  height: 9.5%;
}
.basic {
  .pricing-header {
    background-color: var(--dark-grey);
  }
  .price-circle {
    border: 10px solid var(--dark-grey);
    transition: all 0.4s;
  }
  &:hover {
    .price-circle {
      border-width: 5px;
    }
  }
  .buy-now {
    background-color: var(--dark-grey) !important;
    &:hover {
      background-image: none !important;
      background-color: var(--dark-grey) !important;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

.echo {
  .pricing-header {
    background-color: var(--highlight-green);
  }
  .price-circle {
    border: 10px solid var(--highlight-green);
    transition: all 0.4s;
  }
  &:hover {
    .price-circle {
      border-width: 5px;
    }
  }
  .buy-now {
    background-color: var(--highlight-green) !important;
    &:hover {
      background-image: none !important;
      background-color: var(--highlight-green) !important;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

.pro {
  .pricing-header {
    background-color: var(--medium-green);
  }
  .price-circle {
    border: 10px solid var(--medium-green);
    transition: all 0.4s;
  }
  &:hover {
    .price-circle {
      border-width: 5px;
    }
  }
  .buy-now {
    background-color: var(--medium-green) !important;
    &:hover {
      background-image: none !important;
      background-color: var(--medium-green) !important;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

.business {
  .pricing-header {
    background-color: var(--dark-green);
  }
  .price-circle {
    border: 10px solid var(--dark-green);
    transition: all 0.4s;
  }
  &:hover {
    .price-circle {
      border-width: 5px;
    }
  }
  .buy-now {
    background-color: var(--dark-green) !important;
    &:hover {
      background-image: none !important;
      background-color: var(--dark-green) !important;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

.buy-now-hover {
  background-image: none !important;
  background-color: var(--dark-green) !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}

.business-hover {
  .price-circle {
    border-width: 5px;
  }
}

.pricing-card-hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}

/* Card Themes - End */

.label {
  padding: auto;
}

.column {
  flex: 25%;
  padding: 20px;
  /* width: calc(33.3% - 30px); */
  width: 20%;
  max-width: 22%;
  height: 710px;
  box-sizing: border-box;

  @media screen and (max-width: 980px) {
    flex: 50%;
    display: block;
  }
  @media screen and (max-width: 700px) {
    flex: 100%;
    display: block;
  }
}

.pricing-card {
  /* @include transition(0.4s, background-color); */
  position: relative;
  height: 100%;
  width: 165.61px;
  background-color: white;
  -moz-border-radius: var(--radius);
  -webkit-border-radius: var(--radius);
  border-radius: var(--radius);
  position: relative;
  transition: all 0.4s;
  display: inline-block;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    transform: scale(1.05);
  }

  .popular {
    position: absolute;
    top: 0;
    right: 5%;
    width: auto;
    padding: 5%;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    background-color: #eb3b5a;
    color: white;
    font-size: 12px;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .badge-box {
    padding: 0 40px;
    margin-top: 80px;
    span {
      display: inline-block;
      border: 1px solid #000;
      padding: 4px 12px;
      border-radius: 25px;
      overflow: hidden;
      color: #000;
    }
  }

  .pricing-header {
    width: 100%;
    height: 150px;
    position: relative;
    border-radius: var(--radius) var(--radius) 0 0;
    -webkit-border-radius: var(--radius) var(--radius) 0 0;
    -moz-border-radius: var(--radius) var(--radius) 0 0;

    .plan-title {
      font-size: 24px;
      color: white;
      position: relative;
      top: 25%;
    }

    .price-circle {
      width: calc(33.3% - 30px);
      width: 120px;
      height: 120px;
      border-radius: 100%;
      left: calc(50% - 60px);
      top: 60%;
      background-color: white;
      position: absolute;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
      .info {
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: gray;
      }

      .price-title {
        display: block;
        font-size: 28px;
        padding: 28px 0 0;
        font-weight: bold;
        small {
          font-size: 18px;
        }
      }
    }
    h2 {
      position: relative;
      top: 40%;
      color: #fff;
    }
  }

  ul {
    margin: 10px 0 0 0;
    padding: 0;
    li {
      list-style-type: none;
      display: block;
      padding: 15px 0 15px 0;
      margin: 0;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .buy-button-box {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 20%;

    .buy-now {
      text-decoration: none;
      color: white;
      padding: 5% 10%;
      border-radius: var(--radius);
      background-color: var(--grey);
    }
  }
}

s {
  color: var(--dark-grey);
}

@media screen and (max-width: 1200px) {
  .container-plans-page {
    position: absolute;
    left: 17%;
  }
  .container-plans .column {
    position: relative;
    width: 100%;
  }

  .buy-button-box {
    top: 0%;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .container-plans-page {
    position: absolute;
    left: 17%;
  }
  .container-plans {
    width: 100%;
    display: grid;
    margin-bottom: 3%;
  }
  #FREE {
    width: 100%;
  }
  ul {
    display: none;
  }
  .column {
    font-size: small;
    position: relative;
    width: 180px;
    margin: 2%;
    max-width: none;
    height: 100%;
  }
  .buy-button-box {
    position: relative;
  }
  .buy-now {
    top: 0%;
    margin-bottom: 5%;
  }
}

@media screen and (max-height: 530px) {
  ul {
    display: none;
  }
  .column {
    height: 440px;
  }
}
</style>
