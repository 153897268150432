<template>
  <div class="box">
    <div @click="clickRow($event, id)" class="box-row">
      <div class="box-row-icon">
        <i class="fa-solid fa-angle-right" :id="`arrow-${id}`"></i>
      </div>
      <div class="box-row-name">
        {{ name }}
      </div>
      <div class="box-row-tag">
        {{ tag === '-' ? ' ' : tag }}
      </div>
      <div class="box-row-confidence">
        {{ confidence === '-' ? ' ' : confidence }}
      </div>
      <div class="box-row-trash" @click="deleteFile">
        <span class="trash">
          <span></span>
          <i></i>
        </span>
      </div>
    </div>
    <div :id="`toggle-${id}`" class="box-toggle">
      <div :id="`waveform-${id}`" class="toggle-waveform"></div>
      <div class="toggle-properties">
        <div class="filename-property">
          <span class="span-property">Name:</span> {{ name }}
        </div>
        <div v-if="tag === '-'" class="filename-tag">
          <span class="span-property">Tag:</span> -
        </div>
        <div v-else class="filename-tag">
          <span class="span-property">Tag:</span>{{ tag }} - {{ confidence }}
        </div>
        <div
          @mouseenter="showBanner(id)"
          @mouseleave="removeBanner(id)"
          class="feedback-tag"
        >
          <span class="span-property">Feedback:</span>
          <button
            @click="pressFeedback('up', id)"
            :class="{ 'button-feedback': true, active: this.feedback === 'up' }"
            :disabled="tag === '-'"
            :id="`button-up-${id}`"
          >
            <i
              :class="{
                'fa-solid': true,
                'fa-thumbs-up': true,
                active: this.feedback === 'up'
              }"
              :id="`thumb-up-${id}`"
            ></i>
          </button>
          <button
            @click="pressFeedback('down', id)"
            :class="{
              'button-feedback': true,
              active: this.feedback === 'down'
            }"
            :disabled="tag === '-'"
            :id="`button-down-${id}`"
          >
            <i
              :class="{
                'fa-solid': true,
                'fa-thumbs-down': true,
                active: this.feedback === 'down'
              }"
              :id="`thumb-down-${id}`"
            ></i>
          </button>
          <div class="disabled-message" :id="`disabled-message-${id}`">
            Recognise sounds before leaving a feedback!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import WaveSurfer from 'wavesurfer.js'
import axios from 'axios'
export default {
  name: 'DataRow',
  props: ['name', 'tag', 'confidence', 'file', 'id', 'file_id'],
  data() {
    return {
      // If the dropdown box is visible
      visible: false,
      // Variable to store the feedback
      feedback: null
    }
  },
  mounted() {
    // Hide toggle elements
    $(`#toggle-${this.id}`).hide()
    // Hide banner associated with the feedback
    $(`#disabled-message-${this.$props.id}`).hide()

    // Create URL
    const url = URL.createObjectURL(this.$props.file)

    // Create wavesurfer element
    const wavesurfer = WaveSurfer.create({
      container: `#waveform-${this.$props.id}`,
      waveColor: '#8ce5aa',
      progressColor: '#2d4e4e',
      barWidth: 0,
      normalize: true,
      mediaControls: true,
      width: 400
    })

    // Load audio
    wavesurfer.load(url)
  },
  methods: {
    clickRow($event, id) {
      // Open the window
      $(`#toggle-${id}`).slideToggle('slow')

      // Toggle box-info visibility
      this.visible = !this.visible

      // Rotate the arrow depending on visibility
      if (this.visible) {
        // Rotate the arrow clockwise
        $(`#arrow-${id}`).css({ rotate: '90deg' })
      } else {
        // Rotate the arrow clockwise
        $(`#arrow-${id}`).css({ rotate: '0deg' })
      }
    },
    pressFeedback(value, id) {
      // Store the feeback passed
      this.feedback = value

      // Animate the button
      $(`#thumb-${value}-${id}`).animate({ rotate: '360deg' }, 'fast')

      // Disable both buttons
      $(`#button-up-${id}`).prop('disabled', true)
      $(`#button-down-${id}`).prop('disabled', true)
      var feedback = ''
      if (value == 'down') {
        feedback = false
      } else {
        feedback = true
      }
      var human_tags = []
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      var data = {
        id: this.$props.file_id,
        feedback: feedback,
        human_tags: human_tags
      }
      setTimeout(() => {
        console.log(this.$props.file_id)
        axiosInstance
          .patch('/feedback/edit', data)
          .then((response) => {
            console.log('PATCH request successful')
            console.log('Response:', response.data)
          })
          .catch((error) => {
            console.error('Error making PATCH request:', error)
          })
      }, 2000)
    },
    deleteFile() {
      this.$emit('delete', this.id)
    },
    showBanner(id) {
      // If feedback buttons are disabled and API not invoked yet
      if ($(`#button-up-${id}`).is(':disabled') && this.$props['tag'] === '-') {
        // Activate banner sliding it up
        $(`#disabled-message-${id}`).slideDown('fast')
      }
    },
    removeBanner(id) {
      // Activate banner sliding it up
      $(`#disabled-message-${id}`).slideUp('fast')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-angle-right {
  transition: all 0.2s ease-in-out;
}

.box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
  justify-content: center;
}

.box-row {
  width: 80%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--highlight-green);
  border-radius: 8px;
  margin-top: 4px;
  cursor: pointer;
}

.box-row:hover {
  scale: 1.02;
  background-color: var(--yellow);
  transition: scale 0.2s ease-in-out;
}

.box-row-icon {
  margin: 0px;
  padding: 8px 5px;
  width: 5%;
  text-align: center;
}

.box-row-name {
  margin: 0px;
  padding: 8px 5px;
  width: 45%;
  text-align: center;
  overflow: hidden;
}

.box-row-tag {
  margin: 0px;
  padding: 8px 5px;
  width: 25%;
  text-align: center;
}

.box-row-confidence {
  margin: 0px;
  padding: 8px 5px;
  width: 25%;
  text-align: center;
}

.box-row-trash {
  padding: 8px 5px;
  width: 10%;
  text-align: center;
  margin-top: 1%;
  height: 80%;
  overflow: visible;
}

.box-toggle {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 182px;
}

.span-property {
  color: var(--dark-green);
  margin-right: 6px;
  overflow: hidden;
  font-weight: bold;
  font-size: medium;
  text-align: left;
}

.toggle-waveform {
  width: 50%;
}

.toggle-properties {
  width: 50%;
  text-align: left;
  font-size: small;
  padding: 10px 28px;
}

div {
  overflow: hidden;
  white-space: nowrap;
}

.filename-tag {
  margin: 4px 0px;
}

.filename-property {
  margin: 4px 0px;
}

.feedback-tag {
  height: 33%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.fa-thumbs-up,
.fa-thumbs-down {
  color: var(--dark-grey);
  transition: all 0.3s ease-in-out;
}

.button-feedback {
  cursor: pointer;
  padding: 6px;
  margin-left: 20px;
  padding: 4px;
  height: 40px;
  width: 40px;
  border: solid 2px var(--dark-grey);
  background-color: var(--white);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.button-feedback:hover {
  scale: 1.2;
  transition: all 0.3s ease-in-out;
}

.button-feedback:disabled {
  cursor: not-allowed;
}

button.active {
  border: solid 2px var(--yellow);
}

.disabled-message {
  white-space: normal;
  margin-left: 3%;
  padding: 3px 4px;
  height: 40px;
  width: 40%;
  background-color: var(--highlight-green);
  color: var(--dark-green);
  border-radius: 8px;
}

.active {
  color: var(--highlight-green);
}
.trash {
  background: var(--dark-green);
  width: 14px;
  height: 16px;
  display: inline-block;
  margin: 0 auto;

  position: relative;
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.trash span {
  position: absolute;
  height: 2px;
  background: var(--dark-green);
  top: -2.5px;
  left: -2px;
  right: -2px;

  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.trash span:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 1.5px;
  background: var(--dark-green);
  top: -2px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
  left: 6.5px;
}

.trash i {
  position: relative;
  width: 1px;
  height: 10px;
  background: #fff;
  display: block;
  margin: 3px auto;
  border-radius: 5px;
}
.trash i:after {
  content: '';
  width: 1px;
  height: 10px;
  background: #fff;
  position: absolute;
  left: -3px;
  border-radius: 5px;
}
.trash i:before {
  content: '';
  width: 1px;
  height: 10px;
  background: #fff;
  position: absolute;
  right: -3px;
  border-radius: 5px;
}

.trash:hover span {
  transform: rotate(-45deg);
  transition: transform 250ms;
}
</style>
