import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/home.vue';
import Login from './views/login.vue';

const routes = [
  { path: '/', component: Home, meta: { requiresAuth: true },},
  { path: '/login', component: Login},
  { path: '/signup', component: Login, props: { param: 'signup' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated using session variable
    const isAuthenticated = sessionStorage.getItem('authenticated') === 'true';
    if (!isAuthenticated) {
      // User is not authenticated, redirect to login page
      next('/login');
    } else {
      // User is authenticated, proceed to the requested route
      next();
    }
  } else {
    // If the route does not require authentication, proceed as normal
    next();
  }
});

export default router;


// router.js

/* import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/Login.vue';
import Dashboard from './views/Dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: true } // Add meta field to indicate authentication requirement
  }
];

const router = new VueRouter({
  routes
});

// Define a navigation guard
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is authenticated (you need to implement this logic)
    const isAuthenticated = checkAuthentication(); // Implement this function
    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      next('/login');
    } else {
      // Continue to the requested route
      next();
    }
  } else {
    // Continue to the requested route
    next();
  }
});

export default router; */