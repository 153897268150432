<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
:root {
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--highlight-green);
}

i {
  font-size: 24px;
}
</style>
