<template>
  <div class="container-dashboard">
    <main id="main-content">
      <header>
        <!-- <button class="menu-btn" id="menu-open" @click="menuOpen">
                    <i class='bx bx-menu'></i>
                </button> -->
        <h5>
          Hey <b> {{ firstname }}</b
          >!
        </h5>
      </header>

      <div class="separator" id="mymodels">
        <div class="info">
          <h3 class="info-title">My AI Models</h3>
          <div class="info-box">
            <i class="bx bx-info-circle"></i>
            <p class="hidden">
              The user has access to the models listed here below.
              <br />
              Each model has its own name and tags.
              <br />
              <br />
              The model name is required when the user uses the sound API since
              different models have different tags and capabilities.
              <br />
              <br />
              For each model, the tags refer to the different type of sounds the
              model is able to distinguish.
            </p>
          </div>
        </div>
      </div>

      <div class="analytics">
        <div>
          <!-- Iterate over the models array -->
          <div v-for="(model, index) in this.models" :key="index" class="item">
            <div class="progress">
              <div class="info">
                <h5>{{ model.name }}</h5>
                <p>{{ model.tags_number }} Tags</p>
                <p>
                  <em>{{ model.custom ? 'Custom' : 'Standard' }}</em>
                </p>
              </div>
            </div>
            <i class="bx bx-equalizer item-icon"></i>
          </div>
        </div>
        <!-- <div class="item">
                    <div class="progress">
                        <div class="info">
                            <h5>generic</h5>
                            <p>527 Tags</p>
                            <p><em>Standard</em></p>
                        </div>
                    </div>
                    <i class='bx bx-equalizer item-icon'></i>
                </div> -->
        <!-- <div class="item">
                    <div class="progress">
                        <div class="info">
                            <h5>aircraft</h5>
                            <p>2 Tags</p>
                            <p><em>Custom</em></p>
                        </div>
                    </div>
                    <i class='fas fa-plane-arrival item-icon'></i>
                </div>
                <div class="item">
                    <div class="progress">
                        <div class="info">
                            <h5>road</h5>
                            <p>8 Tags</p>
                            <p><em>Standard</em></p>
                        </div>
                    </div>
                    <i class='bx bxs-car item-icon' ></i>
                </div>
                <div class="item">
                    <div class="progress">
                        <div class="info">
                            <h5>machine-anomaly</h5>
                            <p>2 Tags</p>
                            <p><em>Custom</em></p>
                        </div>
                    </div>
                    <i class='bx bxs-cog item-icon'></i>
                </div> -->
      </div>

      <div class="separator">
        <div class="info">
          <h3>My Tokens</h3>
        </div>
      </div>
      <div class="planning" v-if="!is_active">
        <h4>Buy a new subscription to get new tokens!</h4>
      </div>
      <div class="planning" v-if="is_active">
        <div v-for="(token, index) in tokens" :key="index" class="item">
          <div class="left">
            <div class="icon">
              <b>{{ initials }}</b>
            </div>
            <div class="details">
              <h5>{{ firstname }}'s Token</h5>
              <p>{{ token.name }}</p>
            </div>
            <input
              :id="'token-input-' + index"
              class="token-input"
              type="password"
              :value="token.token"
              readonly
            />
            <div class="token-utils" v-if="is_active">
              <button
                class="token-button"
                @click="toggleTokenVisibility(index)"
              >
                <i
                  v-if="!token.visibility"
                  class="fa-regular fa-eye-slash token"
                  id="icon-hidden"
                ></i>
                <i v-else class="fa-regular fa-eye token" id="icon-visible"></i>
              </button>
              <button class="token-copy token-button" @click="copyToken(index)">
                <i class="bx bx-copy"></i>
              </button>
              <button
                class="token-delete token-button"
                @click="showTokenDeleteWarning(index)"
              >
                <i class="bx bx-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <dialog id="token-delete-warning" :index="index">
          <p id="dialog-delete-token">
            Are you sure you want to delete this token? <br />This action cannot
            be undone.
          </p>
          <button class="delete-token del" @click="deleteToken()">
            OK, delete it
          </button>
          <button class="delete-token cancel" @click="closeDialog()">
            Cancel
          </button>
        </dialog>
        <dialog id="token-dialog">Token copied!</dialog>
        <div id="token-request" v-if="is_active">
          <form id="token-form" @submit="generateToken">
            <input
              type="text"
              v-model="tokenName"
              id="tokenName"
              placeholder="Enter your token name"
            />
            <button type="submit" id="getTokenButton">Generate token</button>
          </form>
        </div>
      </div>
    </main>

    <aside class="right-section">
      <div class="top">
        <div class="profile">
          <div class="left">
            <img
              src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_icon.png"
            />
            <div class="user">
              <h5>{{ firstname }} {{ lastname }}</h5>
              <a>{{ price }} Plan</a>
            </div>
          </div>
          <!-- <i class='bx bxs-chevron-right'></i> -->
        </div>

        <!-- <div class="upgrade">
          <h5>Upgrade Your Plan</h5>
          <div class="link" @click="gotoplan()">
            <a href="#"
              >Go to <b>{{ upgrade }}</b></a
            >
            <i class="bx bxs-chevron-right"></i>
          </div>
        </div> -->
      </div>

      <div class="separator" id="first">
        <h4>My Plan</h4>
      </div>

      <div class="stats" id="plan_card">
        <h3 class="plan" id="plan_card_title">
          {{ price }}
        </h3>

        <div class="entry">
          <div class="title">
            <h4>API Calls</h4>
          </div>
          <div class="value">
            <p>{{ currentUsage }}/{{ limit }}</p>
          </div>
        </div>

        <hr class="solid" />

        <div class="entry">
          <div class="title">
            <h4>Renews On</h4>
          </div>
          <div class="value">
            <p>
              {{ renews_on }}
            </p>
          </div>
        </div>

        <hr class="solid" />

        <div class="entry">
          <div class="title">
            <h4>Additional Cost</h4>
          </div>
          <div class="value">
            <p>0€</p>
          </div>
        </div>

        <button
          id="cancel-subscription"
          @click="showPlanDeleteWarning"
          v-if="is_active && price != 'FREE'"
        >
          Cancel subscription
        </button>
        <dialog id="plan-delete-warning" :index="index">
          <p id="dialog-delete-plan">
            Are you sure you want to delete this plan? <br />This action cannot
            be undone.
          </p>
          <button class="delete-plan del" @click="stopPlan()">
            OK, delete it
          </button>
          <button class="delete-plan cancel" @click="closeDialog()">
            Cancel
          </button>
        </dialog>
      </div>
    </aside>
  </div>
</template>
<script>
import 'boxicons/css/boxicons.min.css'
import moment from 'moment'
import { get_upgrade, get_initials } from '../utils/utils.js'
import axios from 'axios'
export default {
  name: 'DashboardComponent',
  data() {
    return {
      firstname: '',
      lastname: '',
      email: String,
      company: String,
      plans: Object,
      current_plan_id: Number,
      price: '',
      currentUsage: 0,
      limit: 0,
      renews_on: '',
      upgrade: '',
      is_active: Boolean,
      initials: String,
      tokens: [],
      token_visibility: false,
      models: [],
      tokenName: '',
      delete_index: Number
    }
  },
  created() {
    this.fetchdata()
  },
  methods: {
    menuOpen() {
      const sideBar = document.querySelector(
        '.container-dashboard .left-section'
      )
      sideBar.style.top = '0'
    },
    copyToken(index) {
      const input = document.getElementById('token-input-' + index)
      const text = input.value
      const dialog = document.getElementById('token-dialog')
      navigator.clipboard
        .writeText(text)
        .then(() => {
          dialog.style.display = 'flex'
          dialog.showModal()
          document.addEventListener('click', (event) => {
            if (event.target === dialog) {
              dialog.style.display = 'None'
              dialog.close()
            }
          })
        })
        .catch((err) => {
          alert('Failed to copy text: ', err)
        })
    },
    async fetchdata() {
      const requestData = {
        user_id: sessionStorage.getItem('id')
      }
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      // Make a GET request to retrieve plans details
      axiosInstance
        .get('/user/plans', {
          params: requestData
        })
        .then((response) => {
          this.plans = response.data
          const current_plan = this.plans.reduce((prev, current) => {
            return current.is_active ? current : prev
          }, null)
          this.is_active = current_plan['is_active']
          this.current_plan_id = current_plan['id']
          this.price = current_plan['type']
          console.log(this.current_plan_id)
          this.currentUsage = current_plan['current_usage']
          this.limit = current_plan['limit']
          this.renews_on = moment(current_plan['renews_on']).format(
            'DD/MM/YYYY'
          )

          this.upgrade = get_upgrade(this.price)
          /* for (const plan of this.plans) { */
          axiosInstance
            .get('/plan/models', {
              params: { plan_id: current_plan.id }
            })
            .then((response) => {
              for (const model of response.data) {
                this.pushUniqueModel(model)
              }
            })

          axiosInstance
            .get('/plan/tokens', {
              params: { plan_id: this.current_plan_id }
            })
            .then((response) => {
              console.log(response)
              for (const token of response.data) {
                this.tokens.push(token)
              }
            })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })

      // Make a GET request to retrieve user details
      axiosInstance
        .get('/user/get', {
          params: requestData
        })
        .then((response) => {
          this.firstname = response.data['first_name']
          this.lastname = response.data['last_name']
          this.email = response.data['email']
          this.company = response.data['company']
          this.initials = get_initials(this.firstname, this.lastname)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    },
    pushUniqueModel(model) {
      // Check if the model with the same name already exists in this.models
      const existingModel = this.models.find(
        (existingModel) => existingModel.name === model.name
      )

      // If the model with the same name doesn't already exist, push the new model
      if (!existingModel) {
        this.models.push(model)
        console.log(`Model "${model.name}" added to this.models`)
      } else {
        console.log(`Model "${model.name}" already exists in this.models`)
      }
    },
    gotoplan() {
      this.$emit('update:page', 'Plans')
    },

    toggleTokenVisibility(index) {
      const input = document.getElementById('token-input-' + index)
      console.log(input)
      if (input.type === 'password') {
        input.type = 'text'
        this.token_visibility = true
      } else {
        input.type = 'password'
        this.token_visibility = false
      }
    },
    async generateToken() {
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`,
          'Content-Type': 'application/json'
        }
      })
      try {
        await axiosInstance.post('/token/create', {
          user_id: sessionStorage.getItem('id'),
          plan_id: this.current_plan_id,
          name: this.tokenName,
          limit: this.limit
        })
      } catch (error) {
        console.log(error)
      }
    },
    showTokenDeleteWarning(index) {
      const dialog = document.getElementById('token-delete-warning')
      dialog.showModal()
      document.addEventListener('click', (event) => {
        if (event.target === dialog) {
          dialog.close()
        }
      })
      this.delete_index = index
    },
    showPlanDeleteWarning(index) {
      const dialog = document.getElementById('plan-delete-warning')
      dialog.showModal()
      document.addEventListener('click', (event) => {
        if (event.target === dialog) {
          dialog.close()
        }
      })
      this.delete_index = index
    },
    async deleteToken() {
      const dialog = document.getElementById('token-delete-warning')
      const tokenToDelete = this.tokens[this.delete_index]['id']
      console.log(tokenToDelete)
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`
        }
      })
      try {
        const token = await axiosInstance.delete(
          `/token/delete?token_id=${tokenToDelete}`
        )
        console.log('Token ' + token + ' deleted.')
      } catch (error) {
        console.log(error)
      }
      dialog.close()
      window.location.reload()
    },
    async stopPlan() {
      const axiosInstance = axios.create({
        baseURL: 'https://auth.risso.ai/',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('myToken')}`
        }
      })
      console.log(this.current_plan_id)
      try {
        await axiosInstance.post('plan/stop?plan_id=' + this.current_plan_id)
        window.location.reload()
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog() {
      const token_dialog = document.getElementById('token-delete-warning')
      token_dialog.close()
      const plan_dialog = document.getElementById('plan-delete-warning')
      plan_dialog.close()
    }
  }
}
</script>
<style scoped>
dialog {
  position: relative;
  background: #f5fcf5;
  border: 0px;
  border-radius: 12px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  top: 50%;
  left: 50%;
}
#token-dialog {
  left: 38%;
  top: 45%;
  margin: 1%;
  width: 10%;
  font-size: 14px;
  height: 5%;
  display: none;
  justify-content: center;
  align-items: center;
}
#token-delete-warning {
  width: 20%;
  font-size: 12px;
  padding: 1%;
  left: 38%;
  top: 52%;
}
#plan-delete-warning {
  width: 20%;
  font-size: 12px;
  padding: 1%;
  left: 72%;
  top: 42%;
}

#plan-delete-dialog {
  width: 100%;
  margin: 1%;
}
.delete-token {
  margin-top: 7%;
  margin-right: 4%;
  padding: inherit;
}
.delete-plan {
  margin-top: 7%;
  margin-right: 4%;
  padding: inherit;
}

.del {
  border: 2px solid red;
  border-radius: 4px;
  cursor: pointer;
}

.cancel {
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

.container-dashboard {
  position: relative;
  width: 83%;
  display: grid;
  grid-template-columns: 55% 45%;
  height: 100vh;
  background: linear-gradient(to right, var(--white), var(--light-green));
  border-left: 2px solid var(--grey);
}
main {
  border-right: 2px solid var(--grey);
  padding: 8% 8% 0 8%;
  position: relative;
}

main header {
  display: flex;
  align-items: center;
  gap: 15px;
}

main header h5 {
  font-size: 18px;
  color: var(--medium-green);
  font-weight: 400;
}

main .separator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

main .separator .info {
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
}

main .separator .info h3 {
  font-size: 30px;
  min-width: 30%;
  max-width: 30%;
}

main .separator .info a {
  margin-top: 6px;
  font-size: 14px;
}

main .separator .search {
  display: flex;
  align-items: center;
  gap: 14px;
}

main .separator .search i {
  font-size: 20px;
  color: var(--dark-green);
}

main .separator .search input {
  border: none;
  font-size: 14px;
  width: 80px;
  outline: none;
}

main .separator .search input::placeholder {
  color: var(--dark-grey);
}

main .analytics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

main .analytics .item {
  display: flex;
  align-items: center;
  justify-content: space-between 10px;
  height: 140px;
  min-width: 214px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
}
.item-icon {
  position: relative;
  margin: auto;
}

#tokenName {
  position: relative;
  border: transparent;
  text-align: center;
  background-color: var(--light-green);
  border-radius: 20px;
  border: 1px solid var(--dark-green);
  width: 63%;
  overflow: hidden;
  padding: 4%;
}
#token-request {
  display: inline-flex;
  position: relative;
  align-items: center;
  margin: auto;
  width: 60%;
  height: 110%;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  background-color: var(--dark-green);
  margin-top: 30px;
}

#getTokenButton {
  border: transparent;
  font-size: 12px;
  text-align: center;
  text-justify: center;
  background: transparent;
  background-color: var(--light-green);
  color: var(--dark-green);
  position: relative;
  font-weight: bold;
  overflow: hidden;
  width: 30%;
  border-radius: 20px;
  border: 1px solid var(--dark-green);
}

#getTokenButton:not([disabled]) {
  cursor: pointer;
}

#cancel-subscription {
  border: transparent;
  font-size: 12px;
  text-align: center;
  text-justify: center;
  background: transparent;
  background-color: var(--light-green);
  color: var(--dark-green);
  position: relative;
  font-weight: bold;
  overflow: hidden;
  width: 50%;
  border-radius: 20px;
  border: 1px solid var(--dark-green);
  margin: auto;
  margin-top: 5%;
  padding: 4%;
}
#cancel-subscription:not([disabled]) {
  cursor: pointer;
}

bx-info-circle {
  margin-top: 0%;
}

.bx-info-circle {
  cursor: pointer;
}

.bx-info-circle:hover + .hidden {
  visibility: visible;
  opacity: 1;
  transition: all 0.7s;
}

.enlarged {
  width: 200px;
  height: 200px;
}

.left-token {
  position: relative;
  width: 100%;
}
#token-form {
  position: relative;
  width: 100%;
  margin: 2%;
  display: inline-flex;
  justify-content: space-between;
}
#token-request:hover {
  transition: 0.5s;
  background-color: var(--light-green);
}
input::placeholder {
  color: var(--dark-green);
}

.token-utils {
  position: relative;
  display: inline-flex;
  /* margin-left: 0%; */
  justify-content: flex-end;
}
.token-copy {
  border: transparent;
  background-color: transparent;
}
.token-copy:not([disabled]) {
  cursor: pointer;
}

.token-delete {
  border: transparent;
  background-color: transparent;
  color: var(--medium-green);
}
.token-delete:not([disabled]) {
  cursor: pointer;
}

.token-button {
  border: transparent;
  background-color: transparent;
  color: var(--medium-green);
  padding-left: 10%;
}

.bx-copy {
  font-size: 1.4em;
}
.bx-trash {
  font-size: 1.4em;
}
.fa-eye {
  font-size: 1.4em;
}
.fa-eye-slash {
  font-size: 1.4em;
}
.fa-circle-down {
  color: var(--medium-green);
}
.p-token {
  text-align: center;
  margin: 2%;
}
form {
  display: inline-flex;
}
main .analytics .item .progress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 50%;
  margin-right: 10%;
}

main .analytics .item .progress .info h5 {
  color: var(--white);
  font-size: 20px;
  font-weight: bold;
}

main .analytics .item .progress .info p {
  color: var(--white);
  font-size: 12px;
}

main .analytics .item i {
  font-size: 80px;
  color: #fff;
}

main .analytics .item:nth-child(1) {
  background: var(--dark-green);
}

main .analytics .item:nth-child(2) {
  background: var(--medium-green);
}

main .analytics .item:nth-child(3) {
  background: var(--dark-green);
}

main .analytics .item:nth-child(4) {
  background: var(--medium-green);
}

main .separator input {
  border: none;
  color: var(--yellow);
  font-weight: bold;
  outline: none;
  width: 100px;
}

main .planning {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
}

main .planning .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
}

main .planning .item .left {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  vertical-align: middle;
  justify-content: space-between;
}

main .planning .item .left .icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

main .planning .item .left .icon {
  border: 1px solid var(--medium-green);
  border-radius: 100%;
}

main .planning .item .left .details h5 {
  font-size: 12px;
  font-weight: 600;
}

main .planning .item .left .details p {
  font-size: 12px;
  color: #bdbdbd;
}

main .planning .item > i {
  cursor: pointer;
}

/* main header .menu-btn{
    display: flex;
} */

main h5 {
  font-size: 16px;
}

main .separator .info {
  gap: 15px;
}

main .separator .info h3,
.container-dashboard .right-section .separator h4 {
  font-size: 20px;
}

main .analytics .item {
  min-width: 160px;
}

main .analytics .item i {
  display: inline-flex;
  position: relative;
  border-left: 0;
}

main .planning {
  grid-template-columns: 1fr;
  gap: 10px;
}

main .planning .item {
  padding: 8px;
}

.plan_not_active {
  background: linear-gradient(
    to right,
    var(--grey),
    var(--dark-grey)
  ) !important;
}
.title_not_active {
  border-color: var(--dark-grey) !important;
}
/* .menu-btn{
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--light-green);
    border-radius: 6px;
    cursor: pointer;
    display: none;
} */

/* .menu-btn i{
    font-size: 18px;
    color: var(--medium-green);
} */
container-dashboard .right-section .separator#first {
  margin-top: 8px;
}

container-dashboard .right-section .separator h4 {
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
}

.info-box {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-height: 100%;
  font-size: 20px;
  padding-left: 3%;
  border-radius: 15px;
  width: 70%;
}

/* .info-box:hover {
    display: inline-flex;
    max-height: 100%;
    background-color: var(--grey);
    width: 100%;
    text-align: left;
    align-items: center;
} */

/* .hidden{
    display: none;
    line-height: 18px;
    font-size: 12px;
    position: absolute;
    transform: translateX(22px) translateY(-22px);
    background-color: var(--grey);
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s, visibility 1s;
} */

.hidden {
  font-size: 12px;
  position: absolute;
  transform: translateX(22px) translateY(82px);
  background-color: var(--grey);
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 1s,
    visibility 1s;
}

.token-input {
  position: relative;
  box-sizing: border-box;
  position: relative;
  border: transparent;
  width: 45%;
  margin-left: 3%;
  background-color: transparent;
}
#visibility-button {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.container-dashboard .right-section .stats .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
  padding: 25px;
  background: var(--dark-green);
  border-radius: 20px;
}

.container-dashboard .right-section .stats .item .top {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.container-dashboard .right-section .stats .top p {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
}

.container-dashboard .right-section .stats .item .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-dashboard .right-section .stats .item .bottom .line {
  width: 4px;
  height: 30px;
  background: var(--highlight-green);
  border-radius: 20px;
}

.container-dashboard .right-section .stats .item .bottom h3 {
  font-size: 40px;
  color: var(--white);
}

.container-dashboard .right-section .stats .entry .title {
  color: var(--white);
  font-size: 16px;
  flex: 1;
  padding-left: 0px;
}

.container-dashboard .right-section .stats .entry .value {
  color: var(--white);
  flex: 1;
  justify-content: flex-end;
  font-weight: 100;
}

.container-dashboard .right-section .stats .plan {
  position: relative;
  color: var(--white);
  font-weight: 1000;
  margin-top: 1vh;
  margin-bottom: 5vh;
  text-transform: uppercase;
  text-align: center;
  align-self: center;
  border: 1px solid var(--white);
  border-radius: 18px;
  padding: 1vh;
  border-color: var(--highlight-green);
}

.container-dashboard .right-section .stats .entry {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  left: 3%;
}

.container-dashboard .right-section .stats {
  position: relative;
  display: flex;
  margin-top: 5%;
  padding: 3vh;
  gap: 2vh;
  background: linear-gradient(to right, var(--dark-green), var(--medium-green));
  border-radius: 20px;
  flex-direction: column;
  min-height: 46%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
}
.container-dashboard .right-section .top .profile .left .user h5 {
  font-size: 14px;
  font-weight: 600;
}

.container-dashboard .right-section .top .profile .left .user a {
  font-size: 12px;
}
.container-dashboard .right-section .top .upgrade i {
  cursor: pointer;
}

.container-dashboard .right-section .top .profile .left {
  display: flex;
  gap: 15px;
}

.container-dashboard .right-section .top .profile .left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.container-dashboard .right-section .top .profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: var(--light-green);
  width: 244px;
  height: 60px;
  border-radius: 16px;
}

.container-dashboard .right-section .top .profile > i {
  cursor: pointer;
}
.container-dashboard .right-section .top > i {
  color: #909090;
  position: relative;
  cursor: pointer;
}

.container-dashboard .right-section .top > i::after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  background: #eb5757;
  border-radius: 50%;
  right: 0;
}
.container-dashboard .right-section .upgrade h5 {
  color: var(--white);
}

.container-dashboard .right-section {
  padding: 8% 8% 0 8%;
  position: relative;
}

.container-dashboard .right-section .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-dashboard .right-section .upgrade .link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-dashboard .right-section .upgrade .link a {
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .container-dashboard {
    grid-template-columns: 55% 45%;
  }

  .main-content,
  .container-dashboard .right-section {
    padding: 8% 8% 8% 8%;
    height: fit-content;
  }

  .container-dashboard .left-section .logo a,
  .container-dashboard .left-section .sidebar a,
  .container-dashboard .left-section .pic img,
  .container-dashboard .left-section .upgrade {
    display: none;
  }
  .token-input {
    width: 28%;
  }

  #getTokenButton {
    width: 60%;
    font-size: 11px;
  }
}

@media screen and (max-width: 992px) {
  .container-dashboard {
    left: 17%;
    top: 0%;
    grid-template-columns: 100%;
    overflow: scroll;
    height: fit-content;
  }

  /* main header .menu-btn{
        display: flex;
    } */

  main h5 {
    font-size: 16px;
  }

  main .separator .info {
    gap: 3%;
  }
  main {
    height: fit-content;
  }
  main .separator .info h3,
  .container-dashboard .right-section .separator h4 {
    font-size: 20px;
  }

  main .analytics .item {
    min-width: 160px;
  }

  main .analytics .item i {
    display: none;
  }

  main .planning {
    grid-template-columns: 1fr;
    gap: 4%;
  }

  main .planning .item {
    padding: 4%;
  }

  #token-request {
    height: 100%;
    width: 100%;
    display: inline-flex;
    padding: 0%;
    margin: 0%;
    margin-top: 2%;
  }

  #token-form {
    position: relative;
    height: 60%;
    width: 60%;
    margin: 0%;
    left: 20%;
    gap: 10%;
  }

  #tokenName {
    width: 60%;
  }
  .token-input {
    width: 28%;
  }

  #getTokenButton {
    width: 60%;
    font-size: 11px;
  }
  /* .container-dashboard .right-section .stats .item{
        height: 160px;
        padding: 20px;
    } */

  /* .container-dashboard .right-section .stats .item .top p{
        font-size: 14px;
    } */

  /* .container-dashboard .right-section .stats .item .bottom h3{
        font-size: 36px;
    } */
}

@media screen and (max-width: 768px) {
  .container-dashboard {
    grid-template-columns: 1fr;
    overflow: scroll;
  }

  #token-request {
    height: 100%;
    width: 100%;
    display: inline-flex;
    padding: 0%;
    margin: 0%;
    margin-top: 2%;
    font-size: smaller;
  }
  main {
    height: fit-content;
  }
  #token-form {
    position: relative;
    height: 60%;
    width: 60%;
    margin: 0%;
    left: 20%;
    gap: 10%;
  }
  main .planning .item .left .icon[data-v-7aef48b4] {
    /* width: 14%; */
    /* height: 14%; */
    width: 40px;
    height: 40px;
    /* border-radius: 12px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #tokenName {
    width: 60%;
    font-size: 8px;
  }

  .token-input {
    width: 28%;
  }

  #getTokenButton {
    width: 60%;
    font-size: 8px;
  }

  /* .container-dashboard .right-section > .top{
        position: absolute;
        top: 5%;
        right: 0;
        width: 100%;
        padding: 20px;
        z-index: -100;
    } */

  .container-dashboard .right-section .weekly {
    margin-bottom: 20px;
  }
}
hr.solid {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.container-dashboard .right-section .upgrade {
  background: var(--dark-green);
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding: 10px;
  width: 244px;
  height: 60px;
  border-radius: 16px;
}
.container-dashboard .right-section .upgrade .link i {
  color: var(--medium-green);
}
</style>
