<template>
  <div class="container-upload">
    <h1 class="title">Upload Files</h1>
    <form id="upload-form">
      <div class="file-upload">
        <input
          type="file"
          id="file-uploader"
          multiple
          @change="uploadFile"
          name="file"
        />
        <i class="bx bx-up-arrow-alt"></i>
      </div>
    </form>

    <table id="upload-table">
      <thead>
        <tr>
          <th id="file-col">File</th>
          <th id="completion-col">Completion</th>
        </tr>
      </thead>
      <tbody>
        <!-- File rows will be added here dynamically -->
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
export default {
  name: 'UploadComponent',
  data() {
    return {
      currentFileIndex: 1
    }
  },
  methods: {
    async uploadFile(event) {
      // Fetch files
      const files = event.target.files
      
      const tableBody = document
        .getElementById('upload-table')
        .getElementsByTagName('tbody')[0]

      for (const file of files) {
        // Insert a new row at the end of the table
        let row = tableBody.insertRow()
        let fileNameCell = row.insertCell(0)
        fileNameCell.style.padding = '2%'
        let completionCell = row.insertCell(1)
        completionCell.style.padding = '2%'

        // Set file name and completion
        fileNameCell.textContent = file.name
        completionCell.textContent = '0%'
      }


      // Construct date to be used as a prefix for the object key
      const today = new Date()

      // Get year, month, and day
      const year = today.getFullYear()

      // Adds 1 because months are 0-indexed, and pads single digit months with a leading 0
      const month = String(today.getMonth() + 1).padStart(2, '0')

      // Pads single digit days with a leading 0
      const day = String(today.getDate()).padStart(2, '0')

      // Format the date as "year-month-day"
      const formattedDate = `${year}-${month}-${day}`

      const URI = 'https://rissoai--upload-api-main.modal.run'
      // Iterate through files, as the uploading procedure goes for each of them
      for (const file of files) {
        try {
          

          // Define the object key pre-prending the email
          const objectKey =
            sessionStorage.getItem('email') +
            '/' +
            formattedDate +
            '/' +
            file.name

          // Define the maximum chunk size, the number in front is the size in MB
          const maxChunkSize = 10 * 1024 * 1024 // 5 MB in bytes

          // Initiate multipart upload interrogating the backend
          const response = await axios.post(
            URI + '/init?object_key=' + objectKey,
            {},
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).catch(function (error) {
            console.log('Error in init')
            throw new Error(`Error in init ${error.message}`)
          });


          // Fetch the data, and in particular the upload ID
          let data = response.data
          let uploadId = data.upload_id

          let partCounter = 1
          let parts = []
          let position = 0

          let totalParts = Math.ceil(file.size / maxChunkSize)
          let uploadedParts = 0

          if(file.size == 0){
            throw new Error('File size equal to 0')
          }

          // Process the file in chunks
          while (position < file.size) {
            // Get the chunk
            let chunk = file.slice(position, position + maxChunkSize)

            // Get pre-signed URL for the chunk
            let presignResponse = await axios.post(
              `${URI}/presign?object_key=${objectKey}&upload_id=${uploadId}&part_number=${partCounter}`
            ).catch(function (error) {
              console.log('Error in presign')
              throw new Error(`Error in generating pre-signed URL for part ${partCounter}. Error: ${error.message}`)
            });

            // Access the presigned URL
            let presignData = presignResponse.data
            let signedUrl = presignData.url

            // Upload the chunk
            let uploadResponse = await fetch(signedUrl, {
              method: 'PUT',
              body: chunk
            }).catch(function (error) {
              console.log('Error in uploading chuck')
              throw new Error(`Error in uploading part ${partCounter}. Error: ${error.message}`)
            });

            // Get the ETag of the chunk
            let etag = uploadResponse.headers.get('ETag')

            // Update the parts array and the position and the counter
            parts.push({ ETag: etag, PartNumber: partCounter })
            position += maxChunkSize
            partCounter++

            // Update part progress bar
            uploadedParts++

            // Update the file progress bar
            tableBody.rows[this.currentFileIndex - 1].cells[1].textContent =
              ((uploadedParts / totalParts) * 100).toFixed(2) + '%'

            // Log success
            console.log(`Uploaded part ${partCounter} successfully.`)
          }

          // Complete the upload once all parts of the file have been uploaded
          await axios.post(`${URI}/complete`, parts, {
            params: {
              object_key: objectKey,
              upload_id: uploadId
            },
            headers: { 'Content-Type': 'application/json' },
          }).catch(function (error) {
            console.log('Error in completing the multi-part upload')
            throw new Error(`Error in completing the multi-part upload ${error.message}`)
          });

            
          console.log('Upload completed successfully.')

          // Update the file progress bar
          this.currentFileIndex++
        } catch (error) {
          console.log('Upload failed:', error)
          let completeResponse = await axios.post(
            URI + '/error?error=' + error,
            {},
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          console.log('Upload failed:', completeResponse.data)
        }
      }

      
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--highlight-green);
}

i {
  font-size: 32px;
}
.container-upload {
  position: relative;
  background-color: transparent;
  width: 83%;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  --light-green: #f5fcf5;
  --medium-green: #73b170;
  --dark-green: #2d4e4e;
  --highlight-green: #8ce5aa;
  --yellow: #cfff04;
  --white: #fff;
  --grey: #eee;
  --dark-grey: #bdbdbd;
  border-left: 2px solid var(--grey);
  background: linear-gradient(to right, var(--white), var(--light-green));
}

.title {
  margin-top: 3.5%;
}

.file-upload {
  height: 130px;
  width: 130px;
  border-radius: 100px;
  position: relative;
  margin-top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    var(--dark-green),
    var(--light-green)
  );
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 100px;

  input[type='file'] {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background-position: 0 -100%;

    color: var(--dark-green);
  }
}
#upload-table {
  width: 50%;
  border-collapse: separate;
  border-spacing: 0 10px;
  table-layout: fixed;
  text-align: left;
  margin-top: 5%;
}

#upload-table th,
#upload-table td {
  padding: 10px;
  border-bottom: 2px solid #f0f0f0;
  background-color: #fff;
}

#upload-table th {
  background-color: var(--light-green);
  border-top: 2px solid var(--dark-green);
}

#upload-table td {
  border-right: 1px solid #f0f0f0;
  padding: 2%;
}

#upload-table td:last-child {
  border-right: none;
}

#file-col {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#completion-col {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.completed{
  background-color: var(--highlight-green);
  padding: 2%;
  border-radius: 10px;
}
@media screen and (max-width: 1200px) {
  .container-upload {
    position: absolute;
    left: 17%;
    background: linear-gradient(to right, var(--white), var(--light-green));
  }
  #upload-table th {
    font-size: 10px;
  }
}
</style>