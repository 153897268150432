<template>
  <div class="container">
    <!-- Fixed sidebar with buttons -->
    <aside class="left-section">
      <div class="logo">
        <!-- <button class="menu-btn" id="menu-close" @click="menuClose">
                <i class='bx bx-menu'></i>
            </button> -->
        <!-- <img src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_icon.png" href="https://risso.ai/"> -->
        <a href="https://risso.ai/" id="keep">
          <img
            src="https://rissoai-soundapi-docs.s3.eu-west-1.amazonaws.com/risso_icon.png"
            alt="Risso Icon"
          />
        </a>
        <a href="https://risso.ai/">Risso AI</a>
      </div>

      <div class="sidebar">
        <div class="item" id="active" @click="openDashboard">
          <i class="bx bx-home-alt-2"></i>
          <a href="#" id="dashboard">Dashboard</a>
        </div>
        <div class="item">
          <i class="bx bx-book"></i>
          <a href="https://www.docs.risso.ai/">API Docs</a>
        </div>
        <!-- <div class="item" @click="openPlans">
          <i class="bx bx-cog"></i>
          <a href="#" id="plans">Plans</a>
        </div> -->
        <div class="item" @click="openDragDrop">
          <i class="fas fa-circle-play"></i>
          <a href="#" id="drag">Playground</a>
        </div>
        <div class="item" @click="openReport">
          <i class="fa-regular fa-folder-open"></i>
          <a href="#" id="report">Report</a>
        </div>
        <div class="item" @click="openDemo">
          <i class="fa-solid fa-parachute-box"></i>
          <a href="#" id="demo">Demo</a>
        </div>
        <div class="item" @click="openUpload">
          <i class="bx bx-cloud-upload"></i>
          <a href="#" id="upload">Upload Files</a>
        </div>
        <div class="item" @click="openHelp">
          <i class="bx bx-help-circle"></i>
          <a href="#" id="help">Help</a>
        </div>
        <div class="item logout" @click="handleLogout">
          <i class="bx bx-log-out-circle"></i>
          <a href="#">Logout</a>
        </div>
      </div>
    </aside>
    <!-- Components to load depending on button clicked-->
    <Dashboard
      v-if="page === 'Dashboard'"
      @update:page="handlepageUpdate"
    ></Dashboard>
    <Plans v-if="page === 'Plans'"></Plans>
    <Drag v-if="page === 'Drag'"></Drag>
    <Upload v-if="page === 'Upload'"></Upload>
    <Demo v-if="page === 'Demo'"></Demo>
    <Report v-if="page === 'Report'" />
    <Help v-if="page === 'Help'" />
  </div>
</template>

<script>
import Dashboard from '../components/dashboard.vue'
import Plans from '../components/plans.vue'
import Drag from '../components/drag.vue'
import Upload from '../components/upload.vue'
import Report from '../components/report.vue'
import Help from '../components/help.vue'

import '@fortawesome/fontawesome-free/css/all.css'
import 'boxicons'
import Demo from '@/components/demo.vue'

export default {
  name: 'HomeView',
  components: {
    Dashboard,
    Plans,
    Drag,
    Upload,
    Report,
    Demo,
    Help
  },
  data() {
    return {
      page: localStorage.getItem('selectedComponent') || 'Dashboard'
    }
  },
  mounted() {
    if (localStorage.getItem('selectedComponent')) {
      const element = document.getElementById(
        localStorage.getItem('selectedComponent').toLowerCase()
      ).parentElement
      this.clickHandle(element)
    }
  },
  methods: {
    clickHandle(element) {
      const sidebarItems = document.querySelectorAll(
        '.container .left-section .sidebar .item'
      )
      sidebarItems.forEach((item) => {
        item.removeAttribute('id')
      })
      if (element.tagName.toLowerCase() === 'div') {
        element.setAttribute('id', 'active')
      } else {
        element.parentNode.setAttribute('id', 'active')
      }
    },
    openDashboard(event) {
      this.clickHandle(event.target)
      this.page = 'Dashboard'
      localStorage.setItem('selectedComponent', 'Dashboard')
    },
    openPlans(event) {
      this.clickHandle(event.target)
      this.page = 'Plans'
      localStorage.setItem('selectedComponent', 'Plans')
    },
    openDragDrop(event) {
      this.clickHandle(event.target)
      this.page = 'Drag'
      localStorage.setItem('selectedComponent', 'Drag')
    },
    openReport(event) {
      this.clickHandle(event.target)
      this.page = 'Report'
      localStorage.setItem('selectedComponent', 'Report')
    },
    openDemo(event) {
      this.clickHandle(event.target)
      this.page = 'Demo'
      localStorage.setItem('selectedComponent', 'Demo')
    },
    openUpload(event) {
      this.clickHandle(event.target)
      this.page = 'Upload'
      localStorage.setItem('selectedComponent', 'Upload')
    },
    openHelp(event) {
      this.clickHandle(event.target)
      this.page = 'Help'
      localStorage.setItem('selectedComponent', 'Help')
    },
    handleLogout() {
      localStorage.clear()
      sessionStorage.clear()
      this.$router.push('/login')
    },
    handlepageUpdate(newPage) {
      // Method to handle redirect to plans from dashboard (right top button)
      if (newPage == 'Plans') {
        const element = document.getElementById('plans')
        this.clickHandle(element)
        this.page = 'Plans'
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

#icon-hidden {
  top: 20%;
}

.container {
  position: absolute;
  left: 0%;
  top: 0%;
  display: inline-flex;
  width: 100%;
  height: 100%;
  margin: 0%;
  padding: 0%;
}

.left-section {
  position: relative;
  left: 0%;
  width: 17%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 40px 0;
  height: 100%;
}

.left-section .logo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
}

.left-section .logo img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.left-section .logo a {
  font-weight: 800;
  font-size: 20px;
  color: var(--dark-green);
}

.left-section .sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-section .sidebar .item {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.left-section .sidebar .logout {
  margin-bottom: 20px;
  margin-top: 20px;
}

.left-section .sidebar i {
  color: var(--dark-green);
  transition: all 0.3s ease;
}

.left-section .sidebar a {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 14px;
  color: var(--dark-grey);
  position: relative;
  transition: all 0.3s ease;
}

.left-section .sidebar .item#active a,
.left-section .sidebar .item#active i,
.left-section .upgrade .link i {
  color: var(--medium-green);
}

.left-section .sidebar .item a::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  background-color: var(--medium-green);
  right: -30px;
  border-radius: 50%;
  top: 0;
  transform: translateY(116%);
  transition: all 0.3s ease;
}

.left-section .sidebar .item#active a::after {
  width: 8px;
  height: 8px;
}

.left-section .pic img {
  width: 160px;
}

.left-section .upgrade .link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-section .upgrade .link a {
  font-size: 12px;
}

.left-section .upgrade h5 {
  color: var(--white);
}

.menu-btn {
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--light-green);
  border-radius: 6px;
  cursor: pointer;
  display: none;
}

.menu-btn i {
  font-size: 18px;
  color: var(--medium-green);
}

@media screen and (max-width: 1200px) {
  .left-section .logo a,
  .left-section .sidebar a,
  .left-section .pic img,
  .left-section .upgrade {
    display: none;
  }

  #keep {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .left-section {
    position: fixed;
    left: 0%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin: 0%;
  }

  .left-section .logo {
    flex-direction: column;
    gap: 20px;
  }

  .left-section .logo {
    display: flex;
  }
}
</style>
